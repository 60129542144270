import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';

import { ReactComponent as Arrow } from '@icons/arrow-right.svg';
import { OnboardingData } from '@app/types';

import s from './OnboardingAlert.module.scss';

interface OnboardingAlertProps {
  onboardingMessage: OnboardingData | undefined;
  className?: string;
  onClick?: () => void;
}

export const OnboardingAlert: React.FC<OnboardingAlertProps> = ({
  onboardingMessage,
  className,
  onClick,
}) => {
  const [isClickable, setIsClickable] = useState(true);

  const handleClick = () => {
    if (isClickable && onClick) {
      onClick();
      setIsClickable(false);
    }
  };

  useEffect(() => {
    if (!isClickable) {
      const timeout = setTimeout(() => {
        setIsClickable(true);
      }, 600);

      return () => clearTimeout(timeout);
    }
  }, [isClickable]);

  if (!onboardingMessage) {
    return null;
  }

  const { id, container, content, arrow, msg, showCircles } = onboardingMessage;

  return (
    <AnimatePresence>
      {content && (
        <m.div
          className={clsx(s.root, className)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={handleClick}
          style={container}
        >
          <AnimatePresence>
            <m.div
              key={id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={s.msgWrap}
              style={onboardingMessage.msg}
            >
              <div
                className={s.arrowWrap}
                style={{
                  top: arrow?.top,
                  left: arrow?.left,
                  right: arrow?.right,
                  bottom: arrow?.bottom,
                }}
              >
                <Arrow
                  className={s.arrow}
                  style={{
                    rotate: arrow?.rotate,
                    transform: arrow?.transform,
                    display: arrow?.display,
                  }}
                />
              </div>
              <p className={s.msg}>{content}</p>
            </m.div>
          </AnimatePresence>

          {showCircles && (
            <div className={s.circlesWrapper}>
              <div className={s.targetCircle} />
              <div className={s.targetCircle} />
              <div className={s.targetCircle} />
            </div>
          )}
        </m.div>
      )}
    </AnimatePresence>
  );
};
