import cn from 'classnames';

import { ReactComponent as Icon } from '@icons/arrow-right.svg';

import s from './VersusLeagueTag.module.scss';

interface VersusLeagueTagProps {
  place: number;
  leagueName: string;
  className?: string;
  onClick: () => void;
}

export const VersusLeagueTag: React.FC<VersusLeagueTagProps> = ({
  place,
  leagueName,
  className,
  onClick,
}) => {
  return (
    <button className={cn(s.root, className)} onClick={onClick}>
      {/* <div className={s.highlight}>#{place}</div>
      <div>in</div> */}
      <div className={s.highlight}>{leagueName}</div>
      <Icon width={24} height={24} />
    </button>
  );
};
