import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { VersusPriceType } from '@app/types';
import {
  ButtonPurchaseStars,
  VersusPriceTag,
  Web3PurchaseStatus,
} from '@components';
import { Button } from '@app/ui-kit';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';
import { useTonClient, useTonConnect } from '@app/hooks';

import s from './PurchaseItemBlock.module.scss';

interface PurchaseItemBlockProps {
  itemId: string;
  itemName: string;
  price: number;
  priceFull?: number;
  priceTon: string | undefined;
  priceStars?: number;
  className?: string;
}

export const PurchaseItemBlock: React.FC<PurchaseItemBlockProps> = ({
  itemId,
  itemName,
  price,
  priceFull,
  priceTon,
  priceStars,
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [purchaseType, setPurchaseType] = useState<VersusPriceType>();
  const { tonConnectUI, wallet } = useTonConnect();
  const { client } = useTonClient();
  const userId = userProfile?.id;
  const purchaseDisabled = isLoading;

  const handlePurchase = async (purchaseType: VersusPriceType) => {
    try {
      if (purchaseType === VersusPriceType.Ton) {
        if (!wallet?.account.address) {
          await tonConnectUI.openModal();

          return;
        }

        setPurchaseType(purchaseType);

        setIsLoading(true);

        await versusCharacterService.purchaseWithTon({
          wallet,
          client,
          tonConnectUI,
          tgUserId: userId,
          shopItemCode: itemId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shopItemPrice: priceTon!,
          itemType: 'cucumber',
          onTxSent: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }

      if (purchaseType === VersusPriceType.Stars) {
        setPurchaseType(purchaseType);

        await versusCharacterService.purchaseItemWithStars({
          shopItemCode: itemId,
          shopItemPrice: price,
          itemType: 'cucumber',
          onInvoicePaid: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const buttonText = () => {
    let buttonText = 'Purchase using ton';

    if (!wallet?.account.address) {
      buttonText = 'Connect Wallet';
    } else if (isLoading) {
      buttonText = 'Processing...';
    }

    return buttonText;
  };

  return (
    <div className={cn(s.root, className)}>
      <h3 className={s.name}>{itemName}</h3>
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={VersusPriceType.Ton}
      />
      {isLoading ? null : (
        <Button
          onClick={() => handlePurchase(VersusPriceType.Ton)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {buttonText()}
        </Button>
      )}
      {priceStars && priceStars > 0 && !purchaseCompleted && (
        <ButtonPurchaseStars
          onClick={() => handlePurchase(VersusPriceType.Stars)}
          disabled={purchaseDisabled}
          stars={priceStars || 0}
        />
      )}
      <Web3PurchaseStatus isCompleted={purchaseCompleted} type={purchaseType} />
    </div>
  );
};
