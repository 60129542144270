import cn from 'classnames';

import { VersusPriceType, VersusSkillUpgradable } from '@app/types';
import {
  ButtonPurchaseStars,
  DoubleProgressBar,
  VersusPriceTag,
  Web3PurchaseStatus,
} from '@components';

import { Button } from '@app/ui-kit';
import { VERSUS_SKILLS } from '@app/constants/versus';
import { useState } from 'react';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';
import { useNavigate } from 'react-router-dom';
import { useTonClient, useTonConnect } from '@app/hooks';

import s from './PurchaseWormBlock.module.scss';

interface PurchaseWormBlockProps {
  code: string;
  logo: string;
  price: number;
  priceFull?: number;
  priceTon: string | undefined;
  priceStars?: number;
  priceType: VersusPriceType;
  startSkills: VersusSkillUpgradable[];
  className?: string;
}

export const PurchaseWormBlock: React.FC<PurchaseWormBlockProps> = ({
  code,
  logo,
  startSkills,
  price,
  priceStars,
  priceFull,
  priceTon,
  priceType,
  className,
}) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const [isLoading, setIsLoading] = useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [purchaseType, setPurchaseType] = useState<VersusPriceType>();
  const { tonConnectUI, wallet } = useTonConnect();
  const { client } = useTonClient();
  const userId = userProfile?.id;
  const balance = userProfile?.score ?? 0;
  const purchaseDisabled =
    isLoading || (priceType === VersusPriceType.Tomato && balance < price);

  const handlePurchase = async (purchaseType: VersusPriceType) => {
    try {
      if (purchaseType === VersusPriceType.Tomato) {
        setIsLoading(true);

        await versusCharacterService.purchaseCharacterWithTomato(code);
        navigate('/shop/worms');
      }

      if (purchaseType === VersusPriceType.Ton) {
        setPurchaseType(purchaseType);

        if (!wallet?.account.address) {
          await tonConnectUI.openModal();

          return;
        }

        setIsLoading(true);

        await versusCharacterService.purchaseWithTon({
          wallet,
          client,
          tonConnectUI,
          tgUserId: userId,
          shopItemCode: code,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shopItemPrice: priceTon!,
          itemType: 'worm',
          onTxSent: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }

      if (purchaseType === VersusPriceType.Stars) {
        setPurchaseType(purchaseType);

        await versusCharacterService.purchaseItemWithStars({
          shopItemCode: code,
          shopItemPrice: price,
          itemType: 'worm',
          onInvoicePaid: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <div className={cn(s.root, className)}>
      <img src={logo} alt={code} className={s.logo} />
      <div className={s.skills}>
        {startSkills.map((skill) => (
          <div key={skill.id} className={s.skill}>
            <div className={s.level}>
              {skill.level}/{skill.maxLevel}
            </div>
            <DoubleProgressBar
              value1={skill.level}
              max1={skill.generalMaxLevel}
              value2={skill.maxLevel}
              max2={skill.generalMaxLevel}
            />
            <div className={s.name}>{VERSUS_SKILLS[skill.id].name}</div>
          </div>
        ))}
      </div>
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={priceType}
      />
      {priceType === VersusPriceType.Tomato && (
        <Button
          onClick={() => handlePurchase(VersusPriceType.Tomato)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {isLoading ? 'Processing...' : 'Purchase'}
        </Button>
      )}
      {(priceType === VersusPriceType.Ton && isLoading) ||
      priceType === VersusPriceType.Tomato ? null : (
        <Button
          onClick={() => handlePurchase(VersusPriceType.Ton)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {!wallet?.account.address ? 'Connect Wallet' : 'Purchase using TON'}
        </Button>
      )}
      {priceStars && priceStars > 0 && !purchaseCompleted && (
        <ButtonPurchaseStars
          onClick={() => handlePurchase(VersusPriceType.Stars)}
          disabled={purchaseDisabled}
          stars={priceStars || 0}
        />
      )}
      <Web3PurchaseStatus isCompleted={purchaseCompleted} type={purchaseType} />
    </div>
  );
};
