import cn from 'classnames';

import { VersusAccessory, VersusAccessoryBonus } from '@app/types';
import { VERSUS_ITEM_BONUS } from '@app/constants/versus';
import { numberWithCommas } from '@app/utils';

import s from './ShopAccessoryBonus.module.scss';

interface ShopAccessoryBonusProps {
  bonus: VersusAccessoryBonus;
  className?: string;
}

export const ShopAccessoryBonus: React.FC<ShopAccessoryBonusProps> = ({
  bonus,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <div className={s.amount}>+{numberWithCommas(bonus.bonusAmount)}</div>
      <img
        src={VERSUS_ITEM_BONUS[bonus.bonusType].image}
        className={s.bonusIcon}
        alt={bonus.bonusType}
      />
      <div>each win</div>
    </div>
  );
};
