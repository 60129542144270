import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { AuthService } from '../AuthService';
import { ConfigService } from '../ConfigService';

@Injectable()
@MakeObservable
export class SocketService {
  public socket: Socket | null = null;

  constructor(
    private readonly configService: ConfigService,
    private readonly authService: AuthService,
  ) {}

  connect() {
    if (this.socket?.connected) {
      console.log('Already connected to server');

      return;
    }

    const token = this.authService.getCredentials();

    if (!token) {
      throw new Error(
        'Cant init websocket connection as auth token not available',
      );
    }

    const options: Partial<ManagerOptions & SocketOptions> = {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
      transports: ['websocket'],
      auth: {
        token,
      },
    };

    this.socket = io(this.configService.wssUrl, options);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  emit(eventName: string, data: unknown) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(eventName, data);
    } else {
      console.error(
        `Cannot emit event: ${eventName}. Socket is not connected.`,
      );
    }
  }

  on<T>(eventName: string, callback: (data: T) => void) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  once<T>(eventName: string, callback: (data: T) => void) {
    if (this.socket) {
      this.socket.once(eventName, callback);
    }
  }

  off<T>(eventName: string, callback?: (data: T) => void) {
    if (this.socket) {
      this.socket.off(eventName, callback);
    }
  }

  onDisconnect(reason: string) {
    console.log(`Socket disconnected: ${reason}`);
  }

  onConnectError(error: Error) {
    console.error(`Connection error: ${error.message}`);
  }
}
