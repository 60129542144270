import { useState } from 'react';

import { SkinItem, EmptyListState, SkinCard } from '@components';

import { ListItems, BottomSheet, Skeleton } from '@ui-kit';
import { SkinRaw } from '@app/types';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '@ui-kit/ToastNotifications';

import { shopService, userService } from '@services';
import { useServiceState } from '@app/common/state';
import shopCardImg from '@media/shop-card.png';

import s from './AccessoriesTab.module.scss';

export const AccessoriesTab = () => {
  const { skins, isShopLooding } = useServiceState(shopService);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSkin, setSelectedSkin] = useState<SkinRaw | null>(null);

  const { userProfile } = useServiceState(userService, ['userProfile']);

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      setSelectedSkin(null);
    }, 400);
  };

  const handleOpen = (skin: SkinRaw) => {
    setIsOpen(true);
    setSelectedSkin(skin);
  };

  const handleBuySkin = async (id: number) => {
    try {
      notifyInfo('Buying skin, please wait...');

      await shopService.buySkin(id);

      notifySuccess('Skin purchased successfully!');

      handleClose();
    } catch (error) {
      notifyError('Error buying skin. Please try again.');

      console.error('Error buying skin:', error);
    }
  };

  const handleActivateSkin = async (skinId: number) => {
    try {
      notifyInfo('Activating skin, please wait...');

      await shopService.activateSkins(skinId);

      notifySuccess('Skin activated successfully!');

      handleClose();
    } catch (error) {
      notifyError('Skin activation failed. Please try again.');

      console.error('Skin activation failed:', error);
    }
  };

  const handleActivate = (skin: SkinRaw) => {
    handleActivateSkin(skin.id);
  };

  const activatedSkinId = userProfile?.skinId || 1;

  return (
    <>
      <EmptyListState
        variant="custom"
        borderRadius="rounded"
        title="Accessories are coming soon"
        desc="Exclusive skins and gear to slap your opponents in style!"
        img={shopCardImg}
        imgWidth={266}
        imgHeight={191}
      />
      {/* <ListItems
        emptyView={
          <EmptyListState variant="nothing-here" borderRadius="rounded" />
        }
        className={s.listWrap}
        // isLoading={isShopLooding}
        skeleton={<Skeleton variant="item" />}
        listClassName={s.list}
        title="Skins"
        items={skins}
        keyExtractor={({ id }) => id.toString()}
        renderItem={(skin) => (
          <SkinItem
            isActivate={activatedSkinId === skin.id}
            skin={skin}
            onClick={() => handleOpen(skin)}
            score={skin.price}
          />
        )}
      />
      <BottomSheet
        className={s.bottomSheet}
        open={isOpen}
        onClose={handleClose}
      >
        {selectedSkin && (
          <SkinCard
            isActivate={activatedSkinId === selectedSkin.id}
            score={selectedSkin.price}
            skin={selectedSkin}
            onActivate={handleActivate}
            onBuy={() => handleBuySkin(selectedSkin.id)}
          />
        )}
      </BottomSheet> */}
    </>
  );
};
