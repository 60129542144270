import React from 'react';
import clsx from 'clsx';

import styles from './StrokeText.module.scss';

interface StrokeTextProps {
  className?: string;
  text: string;
  strokeWidth?: string;
  strokeOffset?: string;
}

export const StrokeText: React.FC<StrokeTextProps> = ({
  className,
  text,
  strokeWidth = '5px',
  strokeOffset = '2px',
}) => {
  const customStyle = {
    '--stroke-width': strokeWidth,
    '--stroke-offset': strokeOffset,
  } as React.CSSProperties;

  return (
    <span
      className={clsx(styles.root, className)}
      data-text={text}
      style={customStyle}
    >
      {text}
    </span>
  );
};
