import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import s from './Page.module.scss';
import { NavBottomBar, NavSideBar } from '../NavBars';
import { useServiceState } from '@app/common/state';
import { questService } from '@app/services';

interface PageProps {
  className?: string;
  children?: React.ReactNode[];
  isHaveSideNav?: boolean;
  isHaveBottomNav?: boolean;
  style?: CSSProperties;
}

export const Page: React.FC<PageProps> = ({
  className,
  children,
  isHaveSideNav = false,
  isHaveBottomNav = true,
  style,
}) => {
  const { quests } = useServiceState(questService, [
    'quests',
    'isQuestsLoading',
  ]);
  const inProgressQuests = quests.filter((q) => {
    const completeTimes = q.completeTimes ?? 0;

    if (q.maxCompleteTimes && completeTimes >= q.maxCompleteTimes) {
      return false;
    }

    return !q.isCompleted && !q.isRewardClaimed;
  });

  return (
    <div className={clsx(s.root, className)} style={style} key="page">
      {children}
      {isHaveBottomNav && (
        <div className={s.menuNav}>
          <NavBottomBar questsQnty={inProgressQuests.length} />
        </div>
      )}
      {isHaveSideNav && <NavSideBar className={s.navSideBar} />}
    </div>
  );
};
