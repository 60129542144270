import React from 'react';
import clsx from 'clsx';

import participateImg from '@media/why-participate.png';

import s from './WhyParticipateItem.module.scss';

interface WhyParticipateItemProps {
  className?: string;
  title: string;
  desc1: string;
  desc2: string;
}

export const WhyParticipateItem: React.FC<WhyParticipateItemProps> = ({
  className,
  title,
  desc1,
  desc2,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <div className={s.wrap}>
        <h4 className={s.title}>{title}</h4>
        <p className={s.desc}>{desc1}</p>
        <p className={s.desc}>{desc2}</p>
      </div>

      <img className={s.img} src={participateImg} alt="participate" />
    </div>
  );
};
