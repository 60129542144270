import React, { useMemo } from 'react';
import clsx from 'clsx';

import { HeroSection } from '../HeroSection';

import heroImg from '@media/maintenance.png';

import s from './Maintenance.module.scss';

interface MaintenanceProps {
  className?: string;
  timestamp: number;
}

export const Maintenance: React.FC<MaintenanceProps> = ({
  className,
  timestamp,
}) => {
  const date = useMemo(() => {
    const _date = new Date(timestamp);

    return `${_date.toLocaleDateString()} ${_date.toLocaleTimeString()}`;
  }, [timestamp]);

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.content}>
        <HeroSection
          img={heroImg}
          imgMargin={'80px 0 0 0'}
          imgHeight={213}
          imgWidth={161}
        />
      </div>
      <div className={s.contentWrap}>
        <p className={s.title}>
          <span>UNDER</span>
          <span>Maintenance</span>
        </p>
        <p className={s.text}>
          We’d tell you if we knew what happened, but we don’t know what’s
          happened. Checking...
        </p>

        <p className={s.text}>
          <span className={s.orange}>Expected to be slapping again by</span>
          <span>{date}</span>
        </p>
      </div>
    </div>
  );
};
