import cn from 'classnames';

import { VersusSkillUpgradable, VersusWssSkill } from '@app/types';
import { DoubleProgressBar } from '@app/components';

import s from './VersusSkillProgress.module.scss';

interface VersusSkillProgressProps {
  data: VersusSkillUpgradable;
  className?: string;
}

export const VersusSkillProgress: React.FC<VersusSkillProgressProps> = ({
  data,
  className,
}) => {
  const { id, level, maxLevel, generalMaxLevel } = data;

  return (
    <div className={cn(s.root, className)}>
      <div className={s.row}>
        <div className={s.level}>
          <span>{level}</span>
          <span>/</span>
          <span>{maxLevel}</span>
        </div>
        <div className={s.skillName}>{description[id].name}</div>
      </div>
      <DoubleProgressBar
        value1={level}
        max1={generalMaxLevel}
        value2={maxLevel}
        max2={generalMaxLevel}
        className={s.bar}
      />
      <div className={s.description}>{description[id].description}</div>
    </div>
  );
};

const description = {
  [VersusWssSkill.Damage]: { name: 'Power', description: 'Slap harder' },
  [VersusWssSkill.Resilience]: {
    name: 'Resilience',
    description: 'Handle slaps easier',
  },
  [VersusWssSkill.Reaction]: {
    name: 'Reaction',
    description: 'Slap with max power',
  },
};
