import React, { useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import { BottomNavItem } from '@app/constants/navItem';
import { NavItem } from '../NavItem';
import { isIOS, openLink } from '@app/utils';

import s from './NavBottomBar.module.scss';

interface NavBottomBarProps {
  className?: string;
  questsQnty: number;
}

export const NavBottomBar: React.FC<NavBottomBarProps> = ({
  className,
  questsQnty,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [activePath, setActivePath] = useState<string>('/');

  const handleNavigateTo = (path: string) => {
    navigate(path);
    // setActivePath(path);
  };

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.bg}></div>
      <ul
        className={s.list}
        style={{
          paddingBottom: isIOS() ? '24px' : '8px',
        }}
      >
        {BottomNavItem.map((el) => (
          <li className={s.item} key={el.label}>
            <NavItem
              icon={
                <img
                  className={s.img}
                  src={el.icon}
                  width={40}
                  height={40}
                  alt="nav icon"
                />
              }
              label={el.label}
              itemQnty={el.label === 'Quests' ? questsQnty : undefined}
              marginTop={el.marginTop}
              iconBgSize="46px"
              iconActiveBg="#ABED5E"
              isActive={el.path === location.pathname}
              onClick={() => {
                handleNavigateTo(el.path);
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
