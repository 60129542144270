import { AirdropRewardMap } from '@app/types/airdrop';
import headImg from '@media/worm-head.png';
import mythicLeagueBigIcon from '@media/leagues/mythic-big.webp';
import auctionIcon from '@media/auction-icon.png';
import questMasteryIcon from '@media/quest-mastery.png';
import acountLifeIcon from '@media/acount-life.png';
import acountRetirementIcon from '@media/retirement.png';

export const airdropRewardMap: AirdropRewardMap = {
  oldAccount: {
    title: 'Retirement Fund!',
    desc: '75 days in play? Get a gift!',
    icon: acountRetirementIcon,
  },
  accountAge: {
    title: 'Longevity Bonus',
    desc: 'Earn 5 $JAM for each day since account registration.',
    icon: acountLifeIcon,
  },
  league: {
    title: 'League Prestige',
    desc: 'Earn $JAM based on your league’s rarity and position in the top 100',
    icon: mythicLeagueBigIcon,
  },
  quests: {
    title: 'Quest Mastery',
    desc: 'Earn 10 $JAM for each quest completed retroactively',
    icon: questMasteryIcon,
  },
  auction: {
    title: 'Auction Victory',
    desc: 'Earn 1,000 $JAM for each auction win',
    icon: auctionIcon,
  },
  friends: {
    title: 'Friendship',
    desc: 'Earn 100 $JAM for inviting 10 or more friends',
    icon: headImg,
  },
};
