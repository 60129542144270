import {
  VersusAccessoryBonusType,
  VersusPriceType,
  VersusWssSkill,
} from '@app/types';
import iconPower from '@media/skills/power.png';
import iconReaction from '@media/skills/reaction.png';
import iconResilience from '@media/skills/resilience.png';
import tomatoIcon from '@media/tomato_coin.png';
import cucumberIcon from '@media/cucumber-icon.png';
import tonIcon from '@media/ton-icon.png';
import tgStarIcon from '@media/telegram-star.png';

export const VERSUS_SKILLS: Record<
  VersusWssSkill,
  { icon: string; name: string }
> = {
  [VersusWssSkill.Damage]: { icon: iconPower, name: 'Power' },
  [VersusWssSkill.Reaction]: {
    icon: iconReaction,
    name: 'Reaction',
  },
  [VersusWssSkill.Resilience]: {
    icon: iconResilience,
    name: 'Resilience',
  },
};

export const VERSUS_ITEM_BONUS = {
  [VersusAccessoryBonusType.Cucumbers]: { image: cucumberIcon },
  [VersusAccessoryBonusType.Tomatoes]: { image: tomatoIcon },
};

export const VERSUS_ITEM_PRICE = {
  [VersusPriceType.Tomato]: {
    icon: tomatoIcon,
    textColor: 'var(--text-primary)',
    backgroundColor: 'var(--decorative-action-red)',
  },
  [VersusPriceType.Ton]: {
    icon: tonIcon,
    textColor: '#fff',
    backgroundColor: '#08C',
  },
  [VersusPriceType.Stars]: {
    icon: tgStarIcon,
    textColor: 'var(--text-secondary)',
    backgroundColor: 'transparent',
  },
};
