import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import { AuthService } from '../AuthService';
import { mapFriendRawToFriend } from './mappers/friend';
import type {
  UserProfile,
  Friend,
  FriendRaw,
  PaginatedResponse,
  Squad,
  Streak,
  MilestoneList,
  MilestoneRaw,
  VersusWssSkill,
} from '@app/types';
import { CacheService } from '../CacheService';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { ConfigService } from '../ConfigService';

@Injectable()
@MakeObservable
export class UserService {
  @observable
  public isAuth = false;

  @observable
  public isTgPremium = false;

  @observable
  public isProfileLoading = false;

  @observable
  public isClaimMilestone = false;

  @observable
  public isClaimReferralsRewards = false;

  @observable
  public userProfile: UserProfile | null = null;

  @observable
  public friends: Friend[] = [];

  @observable
  public milestones: MilestoneRaw[] = [];

  @observable
  public referralsRewards: MilestoneList | null = null;

  @observable
  public streak: Streak | null = null;

  @observable
  public isFriendsLoading = false;

  @observable
  public squad: Squad | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly authService: AuthService,
    private readonly cacheService: CacheService,
    private readonly configService: ConfigService,
  ) {
    this.isAuth = this.authService.isAuth();
  }

  public async login(refUserId?: number): Promise<void> {
    const { accessToken } = await this.apiService.post<
      { accessToken: string },
      { initData: string; refUserId?: number }
    >('/auth/login', {
      initData: this.configService.initData,
      refUserId: refUserId || undefined,
    });

    this.authService.setCredentials(accessToken);
  }

  public async fetchProfile(): Promise<void> {
    this.isProfileLoading = true;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');

    const timestampDelta = Date.now() - this.userProfile.currentTimestamp;

    this.cacheService.set('timestampDelta', timestampDelta);

    document.cookie = `referral=${this.userProfile.invitedByUserId}; path=/;`;
    document.cookie = `tomatoes_balance=${this.userProfile.score}; path=/;`;
    document.cookie = `cucumbers_balance=${this.userProfile.versus?.score ?? 0}; path=/;`;

    this.isProfileLoading = false;
  }

  public async fetchFriends(): Promise<void> {
    const params = { limit: 100 };

    this.isFriendsLoading = true;

    const friendsRaw = await this.apiService.get<PaginatedResponse<FriendRaw>>(
      '/user/my-referrals',
      params,
    );
    const friends: Friend[] = friendsRaw.items.map((item) =>
      mapFriendRawToFriend(item),
    );

    this.isFriendsLoading = false;

    this.friends = friends;
  }

  public async joinSquad(id: number): Promise<void> {
    const body = { id };

    const userProfile = await this.apiService.post<UserProfile>(
      '/squad/join',
      body,
    );

    this.userProfile = userProfile;
  }

  public async leaveSquad(id: number): Promise<void> {
    const body = { id };

    const userProfile = await this.apiService.post<UserProfile>(
      '/squad/leave',
      body,
    );

    this.userProfile = userProfile;
    this.squad = null;
  }

  public async connectWallet(address: string, hash: string): Promise<void> {
    await this.apiService.post<{ address: string; hash: string }>(
      '/user/connect-wallet',
      {
        address,
        hash,
      },
    );
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  public async disconnectWallet(): Promise<void> {
    await this.apiService.post('/user/disconnect-wallet');
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }
  public async authStreak(): Promise<void> {
    const streak = await this.apiService.post<Streak>('/auth-streak');

    this.streak = streak;
    this.userProfile = await this.apiService.get<UserProfile>('/user/profile');
  }

  public async fetchReferralsRewards(): Promise<void> {
    const referralsRewards = await this.apiService.get<MilestoneList>(
      '/referral-claim/list',
    );

    const milestones = referralsRewards.rewardList;

    this.milestones = milestones;
    this.referralsRewards = referralsRewards;
  }

  public async claimMilestone(milestone: number): Promise<void> {
    this.isClaimMilestone = true;

    const referralsRewards = await this.apiService.post<MilestoneList>(
      '/referral-claim/claim-milestone',
      {
        milestone,
      },
    );

    this.referralsRewards = referralsRewards;
    await this.fetchReferralsRewards();
    this.isClaimMilestone = false;
  }

  public async claimReferralsRewards(): Promise<void> {
    this.isClaimReferralsRewards = true;

    const referralsRewards = await this.apiService.post<MilestoneList>(
      '/referral-claim/claim',
    );

    this.referralsRewards = referralsRewards;
    await this.fetchReferralsRewards();
    this.isClaimReferralsRewards = false;
  }

  getRankProgressFromRating(rating: number) {
    const maxProgress = this.userProfile?.versus?.rating.progressMax ?? 7;
    const rank = Math.floor(rating / maxProgress);
    const progress = rating % maxProgress;

    return { rank, progress };
  }

  getRatingFromRankProgress(
    rank: number,
    progress: number,
    progressMax: number,
  ) {
    return rank * progressMax + progress;
  }

  updateProfile(profile: UserProfile) {
    this.userProfile = profile;
  }

  setTgIdForAnalytics() {
    const tgUserData = this.extractTgUserData();

    if (tgUserData) {
      document.cookie = `tg_user_id=${tgUserData.id}; path=/;`;
    }
  }

  extractTgUserData(): WebAppUser | null {
    const parsedInitData = new URLSearchParams(this.configService.initData);
    const user = parsedInitData.get('user');

    if (user) {
      return JSON.parse(decodeURIComponent(user));
    }

    return null;
  }

  checkUserPremium() {
    const tgUserData = this.extractTgUserData();

    if (!tgUserData) {
      return;
    }

    const isTgPremium = Boolean(tgUserData.is_premium);

    document.cookie = `is_tg_premium=${isTgPremium}; path=/;`;

    return isTgPremium;
  }

  async updateServerTgPremiumStatus() {
    const isTgPremium = this.checkUserPremium();
    const isServerPremium = this.userProfile?.isPremium;

    if (isTgPremium !== isServerPremium) {
      await this.login();
      await this.fetchProfile();
    }
  }
}
