import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  LeagueChart,
  LeagueRankName,
  OnboardingAlert,
  Page,
  ProgressionRewards,
  RankBadge,
} from '@app/components';
import { Button } from '@app/ui-kit';

import { useServiceState } from '@app/common/state';
import {
  onboardingService,
  userService,
  versusAudioService,
  versusLeagueService,
} from '@app/services';

import s from './LeagueProgressionPage.module.scss';

const TRANSITION_DURATION_S = 0.6;

export const LeagueProgressionPage: React.FC = () => {
  const navigate = useNavigate();
  const { leagueRewards: rewardsData } = useServiceState(versusLeagueService, [
    'leagueRewards',
  ]);
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const { onboardingMessage, isOnboarding, onboardingLeagueProgress } =
    useServiceState(onboardingService, [
      'onboardingMessage',
      'isOnboarding',
      'onboardingLeagueProgress',
    ]);

  const userRankLevel = isOnboarding
    ? onboardingLeagueProgress.rank
    : userProfile?.versus?.rating.rank || 0;

  const userRankImage = isOnboarding
    ? onboardingLeagueProgress.leagueIcon
    : userProfile?.versus?.rating.leagueIcon;

  const userLeagueName = isOnboarding
    ? onboardingLeagueProgress.leagueName
    : userProfile?.versus?.rating.leagueName || '';

  const leagueRewards = isOnboarding
    ? [onboardingService.onboardingLeagueData, ...rewardsData]
    : rewardsData;

  const userLeagueIndex = leagueRewards
    ?.slice()
    .reverse()
    .findIndex((el) => el.rank <= userRankLevel);

  const correctLeagueIndex =
    userLeagueIndex !== -1 ? leagueRewards.length - 1 - userLeagueIndex : 0;

  const [[currentSelectedLeagueIndex, direction], setSelectedRank] = useState([
    correctLeagueIndex,
    1,
  ]);

  const paginate = (newDirection: number) => {
    const newPageNum = currentSelectedLeagueIndex + newDirection;

    if (newPageNum < 0 || newPageNum >= leagueRewards.length) {
      return;
    }

    setSelectedRank([newPageNum, newDirection]);
  };

  useEffect(() => {
    if (isOnboarding) {
      setSelectedRank([onboardingLeagueProgress.rank, 1]);
    }
  }, [onboardingLeagueProgress, isOnboarding]);

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <RankBadge
        level={userRankLevel}
        rankImage={userRankImage}
        className={s.rankBadge}
        transitionDurationSeconds={TRANSITION_DURATION_S}
      />
      <div className={s.wrapper}>
        <LeagueRankName
          rankName={userLeagueName}
          transitionDurationSeconds={TRANSITION_DURATION_S}
          navDirection={direction}
        />
        <Button
          btnType="link"
          variant="unfilled"
          size="large"
          gap="4px"
          onClick={() => {
            navigate('/league-leaderboard');
          }}
        >
          See leaderboard
        </Button>
      </div>
      <LeagueChart
        userRank={userRankLevel}
        selectedRankIndex={currentSelectedLeagueIndex}
        leagueData={leagueRewards}
        transitionDurationSeconds={TRANSITION_DURATION_S}
        isOnboarding={isOnboarding}
        className={s.chart}
        zIndex={onboardingMessage?.zIndexLeague}
      />
      <ProgressionRewards
        userRank={userRankLevel}
        selectedRankIndex={currentSelectedLeagueIndex}
        leagueData={leagueRewards}
        direction={direction}
        transitionDurationSeconds={TRANSITION_DURATION_S}
        className={s.rewards}
        onChange={paginate}
        isOnboarding={isOnboarding}
        zIndex={onboardingMessage?.zIndexLeague}
      />
      <OnboardingAlert
        className={s.onboarding}
        onboardingMessage={onboardingMessage}
      />
    </Page>
  );
};
