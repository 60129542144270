import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import wormsImage from '@media/shop-for-worms.png';

import s from './ShopForCard.module.scss';

interface ShopForCardProps {
  variant: 'selector' | 'wormdrobe';
  className?: string;
}

interface VariantConfig {
  title: string;
  description: string;
  navigatePath: string;
}

export const ShopForCard: React.FC<ShopForCardProps> = ({
  variant,
  className,
}) => {
  const navigate = useNavigate();
  const { title, description, navigatePath } = variantConfig[variant];

  const handleClick = () => navigate(navigatePath);

  return (
    <button className={cn(s.root, className)} onClick={handleClick}>
      <h3 className={s.title}>{title}</h3>
      <p className={s.description}>{description}</p>
      <img src={wormsImage} alt="worms" className={s.image} />
    </button>
  );
};

const variantConfig: Record<ShopForCardProps['variant'], VariantConfig> = {
  selector: {
    title: 'Shop for Worms',
    description: 'There are tons of cool stuff',
    navigatePath: '/shop/worms',
  },
  wormdrobe: {
    title: 'Shop for Accessories',
    description: 'There are tons of cool stuff',
    navigatePath: '/shop/accessories',
  },
};
