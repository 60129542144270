import { CacheService } from '../CacheService';
import { Injectable } from '@app/common/di';
import { SoundService } from '../SoundService';

@Injectable()
export class RandomSlapSoundService extends SoundService {
  slapSoundNames: string[] = ['slap1', 'slap2', 'slap3', 'slap4'];

  constructor(
    cacheService: CacheService,
    private readonly soundService: SoundService,
  ) {
    super(cacheService);
  }

  async loadSlapSounds() {
    for (const soundName of this.slapSoundNames) {
      const soundUrl = `/sounds/${soundName}.mp3`;
      const arrayBuffer = await this.loadSound(soundUrl);
      const audioBuffer = await this.context.decodeAudioData(arrayBuffer);

      this.soundBuffers[soundName] = audioBuffer;
    }
  }

  async playRandomSlapSound() {
    if (this.soundService.muted) {
      return;
    }

    const randomIndex = Math.floor(Math.random() * this.slapSoundNames.length);
    const selectedSoundName = this.slapSoundNames[randomIndex];
    const soundUrl = `/sounds/${selectedSoundName}.mp3`;

    await this.playSound(soundUrl);
  }
}
