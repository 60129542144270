import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  BadgePoints,
  VersusAvatar,
  VersusLeagueTag,
  WalletButton,
} from '@components';
import { userService, versusCharacterService } from '@app/services';
import { useServiceState } from '@app/common/state';

import s from './VersusHeader.module.scss';

interface VersusHeaderProps {
  className?: string;
}

export const VersusHeader: React.FC<VersusHeaderProps> = ({ className }) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const userName = userProfile?.username;
  const tomatoes = userProfile?.score ?? 0;
  const versus = userProfile?.versus;
  const cucumbers = versus?.score ?? 0;
  const league = versus?.rating.leagueName ?? '';
  const leagueBadge = versus?.rating.leagueIcon ?? '';
  const leagueRank = versus?.rating.leagueRank ?? 0;

  const playerAssets = versusCharacterService.generateCharAssets(
    versus?.character.assetsUrl,
    versus?.character.code,
  );

  return (
    <div className={cn(s.root, className)}>
      <VersusAvatar
        wormImage={playerAssets?.avatarSm || ''}
        leagueImage={leagueBadge}
        className={s.avatar}
      />
      <div className={s.info}>
        <div className={s.userName}>@{userName}</div>
        <VersusLeagueTag
          place={leagueRank}
          leagueName={league}
          onClick={() => navigate('/league-leaderboard')}
        />
      </div>
      <div className={s.points}>
        <BadgePoints value={tomatoes} variant="header-tomato" />
        <BadgePoints value={cucumbers} variant="header-cucumber" />
      </div>
      <WalletButton />
    </div>
  );
};
