import { useEffect, useRef } from 'react';

type TickFunction = (delta: number, timestamp: number) => void;

// export const useLoop = (tick: TickFunction) => {
//   useEffect(() => {
//     let prevTimestamp = 0;
//     let animationFrameId: number;

//     const loop = (timestamp: number) => {
//       if (prevTimestamp === 0) {
//         prevTimestamp = timestamp;
//       }

//       const delta = timestamp - prevTimestamp;

//       tick(delta, timestamp);

//       prevTimestamp = timestamp;
//       animationFrameId = window.requestAnimationFrame(loop);
//     };

//     animationFrameId = window.requestAnimationFrame(loop);

//     return () => {
//       window.cancelAnimationFrame(animationFrameId);
//     };
//   }, [tick]);
// };

export const useLoop = (tick: TickFunction, isActive: boolean) => {
  const tickRef = useRef(tick);
  const isActiveRef = useRef(isActive);

  useEffect(() => {
    tickRef.current = tick;
  }, [tick]);

  useEffect(() => {
    isActiveRef.current = isActive;

    let prevTimestamp = 0;
    let animationFrameId: number;

    const loop = (timestamp: number) => {
      if (!isActiveRef.current) return;

      if (prevTimestamp === 0) {
        prevTimestamp = timestamp;
      }

      const delta = timestamp - prevTimestamp;

      tickRef.current(delta, timestamp);

      prevTimestamp = timestamp;
      animationFrameId = window.requestAnimationFrame(loop);
    };

    if (isActive) {
      animationFrameId = window.requestAnimationFrame(loop);
    }

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [isActive]);
};
