import cn from 'classnames';

import { VersusWssSkillData } from '@app/types';
import { VERSUS_SKILLS } from '@app/constants/versus';

import s from './VersusSkill.module.scss';

interface VersusSkillProps {
  variant: 'statBar' | 'skillsList';
  data: VersusWssSkillData;
  className?: string;
}

export const VersusSkill: React.FC<VersusSkillProps> = ({
  variant,
  data,
  className,
}) => {
  const { id, level } = data;
  const { icon } = VERSUS_SKILLS[id];

  return (
    <div className={cn(s.root, s[variant], className)}>
      <img src={icon} alt={id.toString()} className={s.skillIcon} />
      <div className={s.levelValue}>{level}</div>
    </div>
  );
};
