import clsx from 'clsx';

import styles from './TaskItem.module.scss';
import { ReactComponent as ArrowSvg } from '@icons/arrow-right.svg';
import checkMarkUrlImg from '@icons/check_mark.webp';
import { useServiceState } from '@app/common/state';
import { questService } from '@app/services';
import { useState } from 'react';

interface TaskItemProps {
  className?: string;
  title: string;
  isCompleted: boolean;
  number: number;
  desc?: string;
  disable?: boolean;
  inProgress: boolean;
  payloadParams?: ['email'];
  isChecking?: boolean;
  onClick?: () => void;
  onCheck?: (email: string) => void;
}

export const TaskItem: React.FC<TaskItemProps> = ({
  className,
  title,
  number,
  isCompleted,
  desc,
  disable = false,
  payloadParams,
  inProgress,
  isChecking,
  onClick,
  onCheck,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <div className={clsx(styles.root, className, disable && styles.disable)}>
      <div className={styles.item} onClick={disable ? undefined : onClick}>
        <div className={styles.wrap}>
          {isCompleted && (
            <div className={styles.completed}>
              <img
                className={styles.checkMark}
                src={checkMarkUrlImg}
                alt="check-mark"
              />
            </div>
          )}
          {!isCompleted && !isChecking && (
            <div className={styles.serialNum}>
              <span>{number}</span>
            </div>
          )}

          {isChecking && !isCompleted && (
            <div className={styles.serialNum}>
              <span className={styles.loader}></span>
            </div>
          )}

          <span className={styles.title}>{title}</span>
        </div>

        <ArrowSvg className={styles.icon} />
      </div>
      {desc && <p className={styles.desc}>{desc}</p>}

      {payloadParams && !isCompleted && (
        <label className={styles.label}>
          <input
            className={styles.input}
            name="email"
            placeholder="Email"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <button
            className={styles.btn}
            onClick={() => {
              onCheck?.(inputValue);
            }}
          >
            Complete
          </button>
        </label>
      )}
    </div>
  );
};
