import {
  BackButton,
  BottomButton,
  Page,
  ShopForCard,
  WormCard,
} from '@app/components';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';

import s from './SelectWormPage.module.scss';

export const SelectWormPage: React.FC = () => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { userCharacters } = useServiceState(versusCharacterService, [
    'userCharacters',
  ]);

  const activeCharCode = userProfile?.versus?.character.code;

  const [selectedCardCode, setSelectedCardCode] = useState<string | undefined>(
    activeCharCode,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasCharacters = Boolean(userCharacters?.length);

  const submitDisabled = isSubmitting || activeCharCode === selectedCardCode;

  const navigate = useNavigate();

  const handleSelect = async (code: string) => {
    setSelectedCardCode(code);
  };

  const handleSubmit = async () => {
    if (selectedCardCode) {
      setIsSubmitting(true);

      try {
        await versusCharacterService.selectCharacter(selectedCardCode);
        navigate('/play');
      } catch (e) {
        console.error(e);
      }

      setIsSubmitting(false);
    }
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate('/play')} />
      <h1 className={s.title}>My worms</h1>
      <p className={s.description}>Slap your opponents in style</p>
      {hasCharacters && (
        <>
          <div className={s.cards}>
            {userCharacters?.map((worm) => (
              <WormCard
                wormInfo={worm}
                key={worm.code}
                isSelected={selectedCardCode === worm.code}
                onClick={handleSelect}
              />
            ))}
          </div>
          <ShopForCard variant="selector" />
          <BottomButton
            label="Select"
            disabled={submitDisabled}
            onClick={handleSubmit}
          />
        </>
      )}
      {!hasCharacters && (
        <div className={s.error}>
          No characters?? <br />
          Looks like something is wrong..
        </div>
      )}
    </Page>
  );
};
