import React, { RefObject, useEffect, useMemo } from 'react';
import { clsx } from 'clsx';
import cn from 'classnames';

import { useSpineAnimation } from '@app/hooks';
import logo from '@media/logo-lg.png';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';

import s from './PlayWormAvatar.module.scss';

interface PlayWormAvatarProps {
  className?: string;
}

export const PlayWormAvatar: React.FC<PlayWormAvatarProps> = ({
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const assetsUrl = userProfile?.versus?.character.assetsUrl;
  const charCode = userProfile?.versus?.character.code;
  const wormAssets = versusCharacterService.generateCharAssets(
    assetsUrl,
    charCode,
  );

  const { containerRef, isLoaded, dispose } = useSpineAnimation({
    assetsUrl: assetsUrl,
    charCode: charCode,
    accessoryName: userProfile?.versus?.character.accessories?.[0]?.code,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: { x: -130, y: -10, width: 450, height: 1230 },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={clsx(s.root, className)}>
        <img
          src={wormAssets?.logoLg}
          alt="worm"
          className={cn(s.avatarNameImage, { [s.loaded]: isLoaded })}
        />
        <div
          className={cn(s.worm, { [s.loaded]: isLoaded })}
          ref={containerRef as RefObject<HTMLDivElement>}
        />
      </div>
    </>
  );
};
