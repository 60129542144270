import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import s from './DoubleProgressBar.module.scss';

interface DoubleProgressBarProps {
  value1: number;
  max1: number;
  value2: number;
  max2: number;
  className?: string;
}

export const DoubleProgressBar: React.FC<DoubleProgressBarProps> = ({
  value1,
  max1,
  value2,
  max2,
  className,
}) => {
  const generateWidths = useCallback(() => {
    return {
      firstFill: `${(value1 / max1) * 100}%`,
      secondFill: `${(value2 / max2) * 100}%`,
    };
  }, [max1, max2, value1, value2]);

  const [fillWidths, setFillWidths] = useState(generateWidths);

  useEffect(() => {
    setFillWidths(generateWidths());
  }, [value1, max1, value2, max2, generateWidths]);

  return (
    <div className={cn(s.root, className)}>
      <div
        className={s.secondFill}
        style={{
          width: fillWidths.secondFill,
        }}
      />
      <div
        className={s.firstFill}
        style={{
          width: fillWidths.firstFill,
        }}
      />
    </div>
  );
};
