import cn from 'classnames';

import { ImageLoader, ShopAccessoryBonus, VersusPriceTag } from '@components';
import { numberWithCommas } from '@app/utils';
import { VERSUS_ITEM_BONUS } from '@app/constants/versus';

import s from './ShopAccessoryCard.module.scss';
import { VersusAccessory, VersusPriceType } from '@app/types';
import { versusCharacterService } from '@app/services';

interface ShopAccessoryCardProps {
  accessory: VersusAccessory;
  className?: string;
  onClick: (accessory: VersusAccessory) => void;
}

export const ShopAccessoryCard: React.FC<ShopAccessoryCardProps> = ({
  accessory,
  className,
  onClick,
}) => {
  const {
    name,
    price,
    priceStars,
    priceFull,
    priceType,
    abilities,
    assetsUrl,
    code,
    isOwned,
  } = accessory;
  const image = versusCharacterService.generateAccessoryIcon(assetsUrl, code);

  return (
    <button
      className={cn(s.root, { [s.disabled]: isOwned }, className)}
      onClick={() => onClick(accessory)}
    >
      {isOwned && <div className={s.ownedTag}>Owned</div>}

      <img src={image} alt={name} className={s.image} />
      <div className={s.name}>{name}</div>
      {abilities.map((bonus, i) => (
        <ShopAccessoryBonus
          bonus={bonus}
          className={s.bonus}
          key={bonus.bonusType + i}
        />
      ))}
      <VersusPriceTag
        price={price}
        priceFull={priceFull}
        priceType={priceType}
        className={s.price}
      />
      {priceStars && (
        <VersusPriceTag
          price={priceStars}
          priceType={VersusPriceType.Stars}
          className={s.starsPrice}
        />
      )}
    </button>
  );
};
