import React, { useEffect, useState } from 'react';

import { BottomSheet } from '@app/ui-kit';
import { TomatoRewardPage } from '@components';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';
import { useNavigate } from 'react-router-dom';
import { openLink } from '@app/utils';

export const TomatoRewardBottomSheet: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { streak } = useServiceState(userService, ['streak']);
  const navigate = useNavigate();
  const navigateToHowItWorkPage = () => {
    navigate('/tomato-how-it-work');
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    openLink('https://t.me/wormfare');
  };

  useEffect(() => {
    if (streak?.hasBeenUpdated === true) {
      setIsOpen(true);
    }
  }, [streak]);

  return (
    <BottomSheet open={isOpen} onClose={handleClose} bgColor="#26201C">
      <TomatoRewardPage
        reward={1000}
        isDayFailed={streak?.isLastStreakFailed}
        onClaim={handleClose}
        navigateTo={navigateToHowItWorkPage}
      />
    </BottomSheet>
  );
};
