import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from '@app/ui-kit';
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';
import partnersQuestImg from '@media/completed-day-hero.png';

import s from './HomeWidgetPartnerEngage.module.scss';

interface HomeWidgetPartnerEngageProps {
  className?: string;
}

export const HomeWidgetPartnerEngage: React.FC<
  HomeWidgetPartnerEngageProps
> = ({ className }) => {
  const navigate = useNavigate();

  return (
    <div className={cn(s.root, className)}>
      <img src={partnersQuestImg} className={s.image} alt="slap-2.0" />
      <h5 className={s.title}>Partner Engagement</h5>
      <p className={s.description}>Complete tasks and earn tomatoes</p>
      <Button
        bgColor="var(--action-primary-default)"
        size="medium"
        gap="2px"
        rightIcon={
          <ChevronRight
            width={16}
            height={16}
            color="var(--text-on-actions-dark)"
          />
        }
        variant="tertiary"
        className={s.button}
        onClick={() => navigate('/quests')}
      >
        Earn Tomatoes
      </Button>
    </div>
  );
};
