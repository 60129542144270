import React from 'react';
import clsx from 'clsx';

import { RewardItem, DayChecklist } from '@components';
import { TomatoRewardCard } from './components';
import { Button } from '@ui-kit';
import tomatoIcon from '@media/tomato_coin.png';
import completedDayIcon from '@media/completed-day-hero.png';
import missedDayIcon from '@media/missed-day-hero.png';

import s from './TomatoRewardPage.module.scss';

interface TomatoRewardPageProps {
  className?: string;
  reward: number;
  isDayFailed?: boolean;
  isClaming?: boolean;
  navigateTo: () => void;
  onClaim: () => void;
}

export const TomatoRewardPage: React.FC<TomatoRewardPageProps> = ({
  className,
  reward,
  isDayFailed = false,
  isClaming,
  navigateTo,
  onClaim,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      {!isDayFailed && (
        <TomatoRewardCard
          icon={completedDayIcon}
          title="You’ve Got a Major Headache Now!"
          desc={
            <span>
              <span className={s.yelow}>Come back every day</span> to get free
              tomatoes to get ahead of everyone!
            </span>
          }
        />
      )}

      {isDayFailed && (
        <TomatoRewardCard
          icon={missedDayIcon}
          title="Damn! You’ve missed your streak"
          desc={
            <span>
              That’s not serious. Don’t do that again. Come back every day to
              earn tomatoes. We’re not playing games here, okay?
            </span>
          }
        />
      )}

      <DayChecklist className={s.dayCheck} variant="widget" />

      <RewardItem
        className={s.reward}
        title="You’ve got tomatoes!"
        btnTitle="WTF is this tomato shit?!"
        value={reward}
        icon={tomatoIcon}
        onClick={navigateTo}
      />

      <Button
        onClick={onClaim}
        disabled={isClaming}
        className={s.button}
        size="large"
      >
        {!isDayFailed ? 'Visit Channel and Collect' : 'Okay!'}
      </Button>
    </div>
  );
};
