import React from 'react';
import clsx from 'clsx';

import { Button } from '@ui-kit';
import jamImg from '@media/three-jam.png';

import s from './TomatoHowWorkItem.module.scss';

interface TomatoHowWorkItemProps {
  className?: string;
  title: string;
  desc: string;
  img: string;
  imgPosition?: 'defult' | 'bottom';
  imgHeight?: string;
}

export const TomatoHowWorkItem: React.FC<TomatoHowWorkItemProps> = ({
  className,
  title,
  img,
  desc,
  imgHeight,
  imgPosition = 'defult',
}) => {
  return (
    <div className={clsx(s.root, className, s[imgPosition])}>
      <h4 className={s.title}>{title}</h4>
      <p className={s.desc}>{desc}</p>

      <img className={s.img} src={img} height={imgHeight} alt="jam" />
    </div>
  );
};
