import { airdropRewardMap } from '@app/constants/airdrop';
import { AirdropRaw, Airdrop } from '@app/types/airdrop';

export const mapAirdropRawToAirdrop = (airdropRaw: AirdropRaw): Airdrop => {
  const { oldAccount, accountAge, league, auction, quests, friends } =
    airdropRaw;

  return {
    userId: airdropRaw.userId,
    canClaim: airdropRaw.canClaim,
    totalBalance: airdropRaw.jam,
    claimConditions: airdropRaw.claimConditions,

    scoreReward: airdropRaw.score,
    otherRewards: [
      {
        type: 'OldAccount',
        ...oldAccount,
        ...airdropRewardMap['oldAccount'],
        available:
          airdropRaw.oldAccount.available <= 0
            ? 0
            : airdropRaw.oldAccount.available,
      },
      {
        type: 'AccountAge',
        ...accountAge,
        ...airdropRewardMap['accountAge'],
        available:
          airdropRaw.accountAge.available <= 0
            ? 0
            : airdropRaw.accountAge.available,
      },
      {
        type: 'League',
        ...league,
        ...airdropRewardMap['league'],
        available:
          airdropRaw.league.available <= 0 ? 0 : airdropRaw.league.available,
      },
      {
        type: 'Quests',
        ...quests,
        ...airdropRewardMap['quests'],
        available:
          airdropRaw.quests.available <= 0 ? 0 : airdropRaw.quests.available,
      },
      {
        type: 'Auction',
        ...auction,
        ...airdropRewardMap['auction'],
        available:
          airdropRaw.auction.available <= 0 ? 0 : airdropRaw.auction.available,
      },
      {
        type: 'Friends',
        ...friends,
        ...airdropRewardMap['friends'],
        available:
          airdropRaw.friends.available <= 0 ? 0 : airdropRaw.friends.available,
      },
    ],
  };
};
