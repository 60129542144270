import React from 'react';
import clsx from 'clsx';

import { BadgePoints } from '@components';

import s from './RewardItem.module.scss';
import { Button } from '@app/ui-kit';

interface RewardItemProps {
  className?: string;
  reward: number;
  numberOfFriends?: number;
  disabledBtn?: boolean;
  onClick: () => void;
}

export const RewardItem: React.FC<RewardItemProps> = ({
  className,
  numberOfFriends,
  reward,
  disabledBtn = false,
  onClick,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <div className={s.contentWrap}>
        <BadgePoints
          size="small"
          value={reward}
          className={s.badge}
          variant="default"
        />
        <p className={s.descProgress}>
          Invite <span>{numberOfFriends} </span>
          FREN
        </p>
      </div>
      <Button
        variant="tertiary"
        bgColor="#B8FF66"
        size="small"
        onClick={onClick}
        disabled={disabledBtn}
      >
        Claim
      </Button>
    </div>
  );
};
