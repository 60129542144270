import { useNavigate } from 'react-router-dom';

import { Page } from '@components';
import { BackButton } from '@components';
import heroImage from '@media/leaderboard-coming.png';

import s from './LeaderboardComingPage.module.scss';

export const LeaderboardComingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate('/play')} />
      <h1 className={s.title}>Leaderboard</h1>
      <p className={s.description}>See how you slap against others</p>
      <p className={s.coming}>Coming soon...</p>
      <img src={heroImage} className={s.image} alt="leagues" />
    </Page>
  );
};
