import React, { useMemo } from 'react';
import clsx from 'clsx';

import { DayChecklistItem } from './components';

import s from './DayChecklist.module.scss';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';

interface DayChecklistProps {
  className?: string;
  variant?: 'defult' | 'widget';
}

export const DayChecklist: React.FC<DayChecklistProps> = ({
  className,
  variant = 'defult',
}) => {
  const { streak } = useServiceState(userService, ['streak']);
  const startDateStr = streak?.startDate;

  const startDate = useMemo(() => {
    return startDateStr ? new Date(startDateStr) : new Date();
  }, [startDateStr]);

  const daysDifference = useMemo(() => {
    const currentDate = new Date();

    const timeDiff = currentDate.getTime() - startDate.getTime();

    return Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
  }, [startDate]);

  const days = useMemo(
    () =>
      Array.from({ length: 7 }, (_, i) => ({
        day: i + 1,
        completed: i < daysDifference,
      })),
    [daysDifference],
  );

  return (
    <div className={clsx(s.root, className, s[variant])}>
      {variant === 'widget' && (
        <p className={s.text}>
          Get<span className={s.green}> 10,000</span> tomatoes on
          <span className={s.green}> Day 7</span>
        </p>
      )}
      <div className={s.container}>
        {days.map((dayInfo) => (
          <DayChecklistItem
            key={dayInfo.day}
            numberOfDay={dayInfo.day}
            isCompleted={dayInfo.completed}
            isFinalDay={dayInfo.day === 7 ? true : false}
          />
        ))}
      </div>
    </div>
  );
};
