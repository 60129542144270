import cn from 'classnames';

import { ReactComponent as Success } from '@icons/success.svg';

import s from './Web3PurchaseStatus.module.scss';
import { VersusPriceType } from '@app/types';

interface Web3PurchaseStatusProps {
  isCompleted: boolean;
  type: VersusPriceType | undefined;
  className?: string;
}

export const Web3PurchaseStatus: React.FC<Web3PurchaseStatusProps> = ({
  isCompleted,
  type,
  className,
}) => {
  if (!isCompleted || !type) {
    return null;
  }

  const message = messages[type];

  return (
    <div className={cn(s.root, className)}>
      <Success className={s.icon} />
      <div>{message}</div>
    </div>
  );
};

const messages: Record<VersusPriceType, string> = {
  [VersusPriceType.Ton]:
    'The item will be added to your account once the transaction is confirmed on the blockchain. You may leave this page.',
  [VersusPriceType.Stars]:
    'The item will be added to your account once we process the invoice. You may leave this page.',
  [VersusPriceType.Tomato]: 'Not supported',
};
