import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'framer-motion';

import {
  Page,
  ShopAccessoryCard,
  ShopItemCard,
  VersusHeader,
  WormCard,
} from '@components';
import { BackButton } from '@components';
import { useServiceState } from '@app/common/state';
import { versusCharacterService } from '@app/services';
import { VersusAccessory, VersusWormData } from '@app/types';
import { Tabs } from '@app/ui-kit';
import { useHideBackButton } from '@app/hooks';
import marketplaceImg from '@media/marketplace.png';

import s from './ShopPage.module.scss';

export enum ShopPageParams {
  cucumbers = 'cucumbers',
  worms = 'worms',
  accessories = 'accessories',
}

export const ShopPage = () => {
  useHideBackButton();

  const scrolling = useRef(false);
  const { tab } = useParams<{ tab: ShopPageParams }>();
  const { shopCharacters, shopAccessories, shopItems } = useServiceState(
    versusCharacterService,
    ['shopCharacters', 'shopAccessories', 'shopItems'],
  );
  const wormsRef = useRef(null);
  const accessoriesRef = useRef(null);
  const isWormsInView = useInView(wormsRef, { once: false, amount: 0.1 });
  const isAccessoriesInView = useInView(accessoriesRef, {
    once: false,
    amount: 0.2,
  });

  const navigate = useNavigate();

  const openWormPreview = (worm: VersusWormData) => {
    navigate(`/shop-worm-preview/${worm.code}`);
  };

  const openAccessoryPreview = (accessory: VersusAccessory) => {
    navigate(`/shop-accessory-preview/${accessory.code}`);
  };

  const openItemPreview = (id: string) => {
    navigate(`/shop-item-preview/${id}`);
  };

  const [currentSection, setCurrentSection] = useState<ShopPageParams | null>(
    null,
  );

  const scrollTo = useCallback((sectionId: string, behavior = 'smooth') => {
    const section = document.getElementById(sectionId);

    if (section) {
      scrolling.current = true;
      section.scrollIntoView({
        behavior: behavior as ScrollBehavior,
        block: 'start',
        inline: 'nearest',
      });

      setTimeout(() => {
        scrolling.current = false;
      }, 800);
    }
  }, []);

  useEffect(() => {
    if (tab) {
      setCurrentSection(tab);
      scrollTo(tab, 'instant');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrolling.current) {
      if (isWormsInView) {
        setCurrentSection(ShopPageParams.worms);
      }

      if (isAccessoriesInView) {
        setCurrentSection(ShopPageParams.accessories);
      }
    }
  }, [isAccessoriesInView, isWormsInView]);

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <VersusHeader className={s.header} />
      <div className={s.tabs}>
        <Tabs
          initialActiveTab={tab || ShopPageParams.worms}
          currentActiveTab={currentSection}
          variant="secondary"
          tabs={[
            { key: ShopPageParams.worms, label: 'Worms' },
            { key: ShopPageParams.accessories, label: 'Accessories' },
          ]}
          onTabChange={(tab) => {
            setCurrentSection(tab);
            scrollTo(tab);
          }}
        />
      </div>
      <div className={s.section} id="cucumbers">
        <img src={marketplaceImg} alt="marketplace" className={s.marketImage} />
        <div className={s.cucumberCards}>
          {shopItems
            ?.slice(0, 3)
            .map((item, i) => (
              <ShopItemCard
                key={item.code + i}
                data={item}
                onClick={() => openItemPreview(item.code)}
              />
            ))}
        </div>
      </div>
      <div className={cn(s.section)} id="worms" ref={wormsRef}>
        <h3 className={s.sectionTitle}>Assemble your team</h3>
        <div className={cn(s.cards)}>
          {shopCharacters?.map((worm, i) => (
            <WormCard
              wormInfo={worm}
              key={worm.code + i}
              onClick={() => openWormPreview(worm)}
            />
          ))}
        </div>
      </div>

      <div className={s.section} id="accessories" ref={accessoriesRef}>
        <h3 className={s.sectionTitle}>Refresh your wormdrobe</h3>
        <div className={s.cards}>
          {shopAccessories?.map((accessory) => (
            <ShopAccessoryCard
              accessory={accessory}
              key={accessory.code}
              onClick={openAccessoryPreview}
            />
          ))}
        </div>
      </div>
    </Page>
  );
};
