import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import {
  RankRewardCard,
  FramerDragDetector,
  ClaimLootBottomSheet,
} from '@components';
import { VersusLeagueRewards } from '@app/types';
import { onboardingService, versusAudioService } from '@app/services';

import s from './ProgressionRewards.module.scss';

interface ProgressionRewardsProps {
  direction: number;
  userRank: number;
  selectedRankIndex: number;
  leagueData: VersusLeagueRewards[];
  transitionDurationSeconds: number;
  className?: string;
  zIndex?: number;
  isOnboarding?: boolean;
  onChange: (direction: number) => void;
}

export const ProgressionRewards: React.FC<ProgressionRewardsProps> = ({
  direction,
  userRank,
  selectedRankIndex,
  leagueData,
  transitionDurationSeconds,
  zIndex,
  isOnboarding,
  className,
  onChange,
}) => {
  const [isClaimOpen, setIsClaimOpen] = useState(false);
  const prevDisabled = selectedRankIndex <= 0;
  const nextDisabled = selectedRankIndex >= leagueData.length - 1;

  const handleChange = (direction: number) => {
    const pageNum = selectedRankIndex + direction;

    if (pageNum < 0 || pageNum >= leagueData.length) {
      return;
    }

    onChange(direction);
  };

  if (leagueData[selectedRankIndex].rewards.length === 0) {
    return null;
  }

  const handleOpenClaim = () => {
    setIsClaimOpen(true);
  };

  const handleClose = () => {
    setIsClaimOpen(false);

    if (isOnboarding) {
      onChange(-1);
      onboardingService.completeOnboarding();
    }
  };

  return (
    <div className={cn(s.root, className)} style={{ zIndex }}>
      <ClaimLootBottomSheet
        isOpen={isClaimOpen}
        loot={leagueData[selectedRankIndex].rewards}
        rewardLeagueRank={leagueData[selectedRankIndex].rank}
        onClose={handleClose}
        isOnboarding={isOnboarding}
      />
      <AnimatePresence initial={false} custom={direction} mode="popLayout">
        <motion.div
          key={selectedRankIndex}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            type: 'tween',
            duration: transitionDurationSeconds,
          }}
          className={s.wrapper}
        >
          <FramerDragDetector disabled={isOnboarding} onChange={onChange}>
            <RankRewardCard
              userRank={userRank}
              rewardsData={leagueData[selectedRankIndex]}
              currentIndex={selectedRankIndex}
              prevDisabled={isOnboarding || prevDisabled}
              nextDisabled={isOnboarding || nextDisabled}
              onClaim={handleOpenClaim}
              onPrev={() => handleChange(-1)}
              onNext={() => handleChange(1)}
              className={s.card}
            />
          </FramerDragDetector>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '110vw' : '-110vw',
    };
  },
  center: {
    x: 0,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? '110vw' : '-110vw',
    };
  },
};
