import { Injectable } from '@common/di';
import { ConfigService } from '../ConfigService';
import { UserService } from '../UserService';
import TagManager from 'react-gtm-module';

interface AnalyticsEvent {
  name: string;
  variables?: { [key: string]: string | number };
}

@Injectable()
export class AnalitycsService {
  constructor(
    private readonly configService: ConfigService,
    private readonly userService: UserService,
  ) {}

  public trackEvent({ name, variables }: AnalyticsEvent) {
    // if (this.configService.env !== 'production') {
    //   return;
    // }

    const userId = this.userService.userProfile?.id;
    const referralCode = this.userService.userProfile?.invitedByUserId;
    const walletAddress = this.userService.userProfile?.walletAddress;

    const eventData = {
      event: name,
      game_name: 'slap',
      user_id: userId,
      referred: referralCode ? 'yes' : 'no',
      referrals_code: referralCode || null,
      walletAddress,
      ...variables,
    };

    TagManager.dataLayer({
      dataLayer: eventData,
    });

    console.log(name, eventData);
  }
}
