import cn from 'classnames';

import { RankBadge } from '@components/RankBadge';

import s from './VersusAvatar.module.scss';

interface VersusAvatarProps {
  wormImage: string;
  leagueImage: string;
  className?: string;
}

export const VersusAvatar: React.FC<VersusAvatarProps> = ({
  wormImage,
  leagueImage,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <RankBadge level={null} rankImage={leagueImage} className={s.league} />
      <img src={wormImage} alt="worm" className={s.worm} />
    </div>
  );
};
