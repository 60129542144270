import { useNavigate } from 'react-router-dom';

export let globalNavigate: (path: string, keepHistory?: boolean) => void;

export const GlobalNavigator = () => {
  const navigate = useNavigate();

  globalNavigate = (path: string, keepHistory = true) => {
    navigate(path, { replace: !keepHistory });
  };

  return null;
};
