import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { StrokeText } from '@app/ui-kit';

import s from './NavItem.module.scss';

interface NavItemProps {
  className?: string;
  label: string;
  icon: React.ReactNode;
  iconBg?: string;
  iconActiveBg?: string;
  iconBgSize?: string;
  itemQnty?: number;
  marginTop?: string;
  labelClassName?: string;
  isActive?: boolean;
  onClick: () => void;
}

export const NavItem: React.FC<NavItemProps> = ({
  className,
  label,
  icon,
  iconBg,
  iconActiveBg,
  iconBgSize,
  itemQnty,
  marginTop,
  labelClassName,
  isActive = false,
  onClick,
}) => {
  const customStyle = {
    '--icon-bg': iconBg,
    '--icon-active-bg': iconActiveBg,
    '--icon-bg-size': iconBgSize,
    '--margin-top': marginTop,
  } as React.CSSProperties;

  return (
    <button
      className={clsx(s.root, className, { [s.active]: isActive })}
      onClick={onClick}
      style={customStyle}
    >
      <div className={s.imgWrap}>
        {iconActiveBg && <div className={s.activeBg}></div>}
        {icon}
      </div>

      <p className={clsx(s.label, labelClassName)}>
        <StrokeText text={label} strokeOffset="1px" strokeWidth="3px" />
      </p>

      {itemQnty ? (
        <div className={s.itemQnty}>
          <span>{itemQnty}</span>
        </div>
      ) : null}
    </button>
  );
};
