import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button } from '@app/ui-kit';
import wysonImage from '@media/buy-worms-card-wyson.png';
import recruitsImage from '@media/buy-worms-card-recruits.png';
import recruitsBg from '@media/buy-worms-card-bg-recruits.png';
import wysonBg from '@media/buy-worms-card-bg-wyson.png';

import s from './HomeBuyWormsCard.module.scss';

interface HomeBuyWormsCardProps {
  className?: string;
}

const config = {
  tysonWault: {
    title: 'Wyson vs Waul',
    subtitle: 'today only',
    image: wysonImage,
    bgImage: wysonBg,
  },
  recruitsDrop: {
    title: 'Recruits Drop',
    subtitle: 'Limited Collection',
    image: recruitsImage,
    bgImage: recruitsBg,
  },
};

export const HomeBuyWormsCard: React.FC<HomeBuyWormsCardProps> = ({
  className,
}) => {
  const navigate = useNavigate();

  const now = new Date();
  const cutoffDate = new Date(Date.UTC(2024, 10, 16, 10, 0, 0)); // 16 Nov 2024, 10:00 AM UTC

  const currentConfig =
    now < cutoffDate ? config.tysonWault : config.recruitsDrop;

  console.log(currentConfig);

  return (
    <div
      className={cn(s.root, className)}
      style={{
        backgroundImage: `url(${currentConfig.bgImage})`,
      }}
    >
      <h3 className={s.title}>{currentConfig.title}</h3>
      <h4 className={s.subtitle}>{currentConfig.subtitle}</h4>
      <img src={currentConfig.image} alt="worms" className={s.image} />

      <Button onClick={() => navigate('/shop/worms')} className={s.button}>
        Grab Until they Gone!
      </Button>
    </div>
  );
};
