import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import timerCover from '@media/versus/timer-cover.png';
import { versusService } from '@app/services';
import s from './Timer.module.scss';
import { useServiceState } from '@app/common/state';

interface TimerProps {
  className?: string;
}

export const Timer: React.FC<TimerProps> = ({ className }) => {
  const { timerProgress, isTimerActive } = useServiceState(versusService, [
    'timerProgress',
    'isTimerActive',
  ]);
  const [color, setColor] = useState('#ABED5E');

  useEffect(() => {
    if (timerProgress > 50) {
      setColor('#ABED5E');
    } else if (timerProgress > 25) {
      setColor('#FFC166');
    } else {
      setColor('#FF5C4F');
    }
  }, [timerProgress]);

  return (
    <div className={clsx(s.root, { [s.hide]: !isTimerActive }, className)}>
      <div className={s.container}>
        <img className={s.cover} src={timerCover} alt="cover" />
        <div className={s.progressBg} />
        <div
          className={s.progress}
          style={
            {
              '--progress': timerProgress,
              background: `conic-gradient(transparent calc(360deg - 3.6deg * var(--progress)), ${color} calc(360deg - 3.6deg * var(--progress)))`,
            } as React.CSSProperties
          }
        ></div>
      </div>
    </div>
  );
};
