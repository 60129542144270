import React from 'react';
import clsx from 'clsx';
import { Alert } from '@app/ui-kit';

import { ReactComponent as PendingIcon } from '@icons/pending.svg';

import s from './PendingItem.module.scss';

interface PendingItemProps {
  className?: string;
  numberOfFriends?: number;
}

export const PendingItem: React.FC<PendingItemProps> = ({
  className,
  numberOfFriends,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <div className={s.info}>
        <PendingIcon width={16} height={16} />
        <p>{numberOfFriends} Pending Referrals</p>
      </div>
      <Alert
        className={s.alert}
        variant="warning"
        alertMsg="Your referrals will only count after they manually earn a minimum of 3,500 tomatoes"
      />
    </div>
  );
};
