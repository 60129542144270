import { useEffect } from 'react';

export const useHideBackButton = () => {
  useEffect(() => {
    Telegram.WebApp.BackButton.hide();

    return () => {
      Telegram.WebApp.BackButton.show();
    };
  }, []);
};
