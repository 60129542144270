import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { ProgressItem, PendingItem, RewardItem } from './components';
import { MilestoneRaw } from '@app/types';
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from '@app/ui-kit/ToastNotifications';
import { userService } from '@app/services';
import wormHeadUrl from '@media/worm-head.png';

import s from './InviteProgressWidget.module.scss';

interface InviteProgressWidgetProps {
  className?: string;
  milestones?: MilestoneRaw[];
  numberOfFriends: number;
  maxNumberOfFriends?: number;
  pendingFriends?: number;
  reward: number;
  isDisabledClaim: boolean;
}

export const InviteProgressWidget: React.FC<InviteProgressWidgetProps> = ({
  className,
  milestones,
  numberOfFriends,
  pendingFriends,
  maxNumberOfFriends,
  reward,
  isDisabledClaim,
}) => {
  const handleMilestoneClaimReward = useCallback(
    async (milestoneId: number) => {
      try {
        notifyInfo('Claim reward, please wait...');

        await userService.claimMilestone(milestoneId);

        notifySuccess('Reward successfully received!');
      } catch (error) {
        notifyError('Error claim reward. Please try again.');

        console.error('Claim reward quest:', error);
      }
    },
    [],
  );

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.contentWrap}>
        <div className={s.wrap}>
          <p className={s.title}>You Invited</p>
          <p className={s.desc}>
            <span className={s.numberOfFriends}>{numberOfFriends}</span>frens
          </p>
        </div>
        <img
          className={s.img}
          src={wormHeadUrl}
          alt="Avatar"
          width={69}
          height={101}
        />
      </div>
      <div className={s.container}>
        {pendingFriends && <PendingItem numberOfFriends={pendingFriends} />}
        {milestones?.map((el) => (
          <RewardItem
            key={el.milestone}
            numberOfFriends={el?.milestone}
            reward={el?.amount || 0}
            onClick={() => {
              handleMilestoneClaimReward(el.milestone);
            }}
            disabledBtn={isDisabledClaim}
          />
        ))}
        {reward > 0 && (
          <ProgressItem
            className={s.progress}
            numberOfFriends={numberOfFriends}
            minNumberOfFriends={0}
            maxNumberOfFriends={maxNumberOfFriends || 0}
            reward={reward}
          />
        )}
      </div>
    </div>
  );
};
