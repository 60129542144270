/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { BackButton, Page, PurchaseWormBlock } from '@app/components';
import { useServiceState } from '@app/common/state';
import { versusCharacterService } from '@app/services';
import { useSpineAnimation } from '@app/hooks';

import s from './ShopWormPreviewPage.module.scss';

export const ShopWormPreviewPage: React.FC = () => {
  const navigate = useNavigate();

  const { code } = useParams<{ code: string }>();

  const { userCharacters, shopCharacters } = useServiceState(
    versusCharacterService,
    ['shopCharacters', 'userCharacters'],
  );

  const char = shopCharacters?.find((char) => char.code === code);

  useEffect(() => {
    const isOwned = userCharacters?.find((el) => el.code === code);

    if (!char || isOwned) {
      navigate('/shop/worms');
    }
  }, [char, code, navigate, userCharacters]);

  const assets = versusCharacterService.generateCharAssets(
    char?.assetsUrl,
    code,
  );

  const { containerRef, isLoaded, dispose } = useSpineAnimation({
    assetsUrl: char?.assetsUrl,
    charCode: code,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: {
        x: -160,
        y: -50,
        width: 500,
        height: 1100,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!char) {
    return null;
  }

  return (
    <Page isHaveBottomNav={false}>
      <BackButton onClick={() => navigate('/shop/worms')} />

      <div
        className={s.root}
        style={{
          backgroundColor: isLoaded ? char?.color : 'var(--Primary-BG)',
        }}
      >
        <div
          className={cn(s.worm, { [s.loaded]: isLoaded })}
          ref={containerRef}
        />
        <PurchaseWormBlock
          code={code!}
          logo={assets!.logoLg}
          price={char!.price!}
          priceFull={char.priceFull}
          priceTon={char!.priceTon}
          priceType={char!.priceType!}
          startSkills={char.startSkills!}
          priceStars={char.priceStars}
          className={cn(s.block, { [s.loaded]: isLoaded })}
        />
      </div>
    </Page>
  );
};
