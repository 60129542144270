import { useEffect, useState } from 'react';
import { useServiceState } from '@app/common/state';
import {
  analitycsService,
  authService,
  configService,
  userService,
  versusCharacterService,
  versusLeagueService,
  versusService,
} from '@app/services';

export const useAuth = (refUserId?: number) => {
  const { isAuth } = useServiceState(userService);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);

      try {
        await userService.fetchProfile();
        await userService.authStreak();

        await Promise.all([
          configService.getServerConfig(),
          versusCharacterService.getShopItems(),
          userService.updateServerTgPremiumStatus(),
          versusService.checkForActiveMatch(),
        ]);

        analitycsService.trackEvent({
          name: 'slap_start_game',
          variables: {
            game_name: 'slap',
          },
        });

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (!isAuth) {
      userService.login(refUserId).then(init);

      return;
    }

    init();
  }, [isAuth, refUserId]);

  return { isLoading, isAuth };
};
