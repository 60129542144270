import React from 'react';
import clsx from 'clsx';

import { NavItem } from '../NavItem';
import { SideNavItem } from '@app/constants/navItem';
import { useNavigate } from 'react-router-dom';
import { openLink } from '@app/utils';

import s from './NavSideBar.module.scss';

interface NavSideBarProps {
  className?: string;
}

export const NavSideBar: React.FC<NavSideBarProps> = ({ className }) => {
  const navigate = useNavigate();

  const handleNavigateTo = (path?: string, link?: string) => {
    if (path) {
      navigate(path);
    }

    if (link) {
      openLink(link);
    }
  };

  return (
    <ul className={clsx(s.root, className)}>
      <div className={s.bg}></div>
      {SideNavItem.map((el) => (
        <li className={s.item} key={el.label}>
          <NavItem
            icon={
              <img
                width={el.label === 'Settings' ? 24 : 40}
                height={el.label === 'Settings' ? 24 : 40}
                src={el.icon}
                alt="nav icon"
              />
            }
            label={el.label === 'Settings' ? '' : el.label}
            iconBgSize="48px"
            iconBg={el.bgColor}
            onClick={() => {
              handleNavigateTo(el.path);
            }}
          />
        </li>
      ))}
    </ul>
  );
};
