import {
  CHAIN,
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import {
  Address,
  beginCell,
  Sender,
  SenderArguments,
  storeStateInit,
} from '@ton/core';
import { TonConnectUI, Wallet, WalletInfoWithOpenMethod } from '@tonconnect/ui';
import { useEffect } from 'react';
import { configService } from '@app/services';

export const useTonConnect = (): {
  sender: Sender;
  connected: boolean;
  wallet: Wallet | (Wallet & WalletInfoWithOpenMethod) | null;
  walletAddress: Address | null;
  userFriendlyAddress: string;
  network: CHAIN | null;
  tonConnectUI: TonConnectUI;
} => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  const walletAddress = wallet?.account?.address
    ? Address.parse(wallet.account.address)
    : undefined;

  useEffect(() => {
    document.cookie = `ton_wallet_address=${userFriendlyAddress}; path=/;`;
  }, [userFriendlyAddress]);

  return {
    sender: {
      send: async (args: SenderArguments) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc()?.toString('base64'),
              stateInit: args.init
                ? beginCell()
                    .storeWritable(storeStateInit(args.init))
                    .endCell()
                    .toBoc()
                    .toString('base64')
                : undefined,
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        });
      },
      address: walletAddress,
    },
    connected: !!wallet?.account?.address,
    wallet,
    walletAddress: walletAddress ?? null,
    userFriendlyAddress: userFriendlyAddress,
    // network: configService.env === 'production' ? CHAIN.MAINNET : CHAIN.TESTNET,
    network: CHAIN.MAINNET,
    tonConnectUI,
  };
};
