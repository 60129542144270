import { useNavigate } from 'react-router-dom';

import {
  Page,
  SlapVersusMenu,
  BackButton,
  VersusHeader,
  PlayWormAvatar,
} from '@components';
import { configService, onboardingService } from '@app/services';
import { Button } from '@app/ui-kit';
import { isIOS } from '@app/utils';
import iconEquip from '@media/equip-icon.png';
import iconChange from '@media/change-icon.png';
import { NavItem } from '@app/components/NavBars';
import avatar from '@media/avatar-lg.png';
import { useHideBackButton } from '@app/hooks';

import s from './SlapVersusPage.module.scss';

export const SlapVersusPage = () => {
  useHideBackButton();

  const navigate = useNavigate();

  return (
    <Page className={s.root} style={{ paddingBottom: isIOS() ? 110 : 95 }}>
      <BackButton onClick={() => navigate('/')} />
      <VersusHeader />
      {configService.env !== 'production' && (
        <div className={s.devHeader}>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devResetVersusData()}
          >
            Reset
          </Button>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devCompleteOnboarding()}
          >
            Complete onboarding
          </Button>
          <Button
            variant="unfilled"
            onClick={() => onboardingService.devAddRating(5)}
          >
            Add rank progress
          </Button>
        </div>
      )}
      <NavItem
        className={s.changeButton}
        label={'Change'}
        labelClassName={s.changeLabel}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.changeImage}
              src={iconChange}
              width={64}
              height={64}
              alt="equip"
            />
          </div>
        }
        onClick={() => {
          navigate('/select-worm');
        }}
      />
      <NavItem
        className={s.equipButton}
        label={'Equip'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.equipImg}
              src={iconEquip}
              width={40}
              height={40}
              alt="equip"
            />
          </div>
        }
        onClick={() => {
          navigate('/equip');
        }}
      />
      <PlayWormAvatar className={s.wormAvatar} />
      <SlapVersusMenu />
    </Page>
  );
};
