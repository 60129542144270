import { m, Variants } from 'framer-motion';
import cn from 'classnames';

import bgImage from '@media/earn-hero-bg.png';
import victoryImage from '@media/battle-result/victory-hero.png';
import victoryText from '@media/battle-result/victory-text.png';
import defeatImage from '@media/battle-result/defeat-hero.png';
import defeatText from '@media/battle-result/defeat-text.png';
import tieImage from '@media/battle-result/tie-hero.png';
import tieText from '@media/battle-result/tie-text.png';
import escapedImage from '@media/battle-result/escaped-hero.png';
import escapedText from '@media/battle-result/escaped-text.png';
import { VersusMatchOutcome } from '@app/types';

import s from './VersusBattleResultHero.module.scss';

interface VersusBattleResultHeroProps {
  battleResult: VersusMatchOutcome;
  className?: string;
}

export const VersusBattleResultHero: React.FC<VersusBattleResultHeroProps> = ({
  battleResult,
  className,
}) => {
  console.log(battleResult);

  const { image, text, imageAnimation, textAnimation } = data[battleResult];

  return (
    <div className={cn(s.root, className)}>
      <m.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          type: 'tween',
          duration: 3,
        }}
        src={bgImage}
        alt="background"
        className={s.bgImage}
      />
      <m.div
        className={s.wrapper}
        variants={imageAnimation as Variants}
        initial="initial"
        animate="animate"
      >
        <img src={image} alt="hero" className={s.hero} />
      </m.div>
      <m.div
        className={s.wrapper}
        variants={textAnimation as Variants}
        initial="initial"
        animate="animate"
      >
        <img src={text} alt="result text" className={s.text} />
      </m.div>
    </div>
  );
};

const data = {
  [VersusMatchOutcome.Victory]: {
    image: victoryImage,
    text: victoryText,
    imageAnimation: {
      initial: { opacity: 0, y: -100 },
      animate: {
        opacity: 1,
        y: 0,
        transition: { type: 'spring', bounce: 0.5, duration: 1 },
      },
    },
    textAnimation: {
      initial: { scale: 2, y: -200, opacity: 0 },
      animate: {
        scale: 1,
        y: 0,
        opacity: 1,
        transition: { type: 'spring', bounce: 0.35, duration: 1.5 },
      },
    },
  },
  [VersusMatchOutcome.Defeat]: {
    image: defeatImage,
    text: defeatText,
    imageAnimation: {
      initial: { opacity: 0, y: -10 },
      animate: {
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      },
    },
    textAnimation: {
      initial: { opacity: 0, y: 40 },
      animate: {
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      },
    },
  },
  [VersusMatchOutcome.Draw]: {
    image: tieImage,
    text: tieText,
    imageAnimation: {
      initial: { opacity: 0, y: -100, scale: 1.5 },
      animate: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { duration: 0.7, type: 'spring', bounce: 0.5 },
      },
    },
    textAnimation: {
      initial: { opacity: 0, rotateY: 180 },
      animate: {
        opacity: 1,
        rotateY: 0,
        transition: { duration: 0.5, type: 'tween', delay: 0.2 },
      },
    },
  },
  [VersusMatchOutcome.Retreat]: {
    image: escapedImage,
    text: escapedText,
    imageAnimation: {
      initial: { x: -200, opacity: 0 },
      animate: {
        x: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring', bounce: 0.3 },
      },
    },
    textAnimation: {
      initial: { x: 200, opacity: 0 },
      animate: {
        x: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring', bounce: 0.3 },
      },
    },
  },
};
