import React from 'react';
import clsx from 'clsx';

import s from './ProgressBar.module.scss';

interface ProgressBarProps {
  className?: string;
  variant?: 'rounded' | 'rectangular' | 'vertical' | 'custom';
  currentNumber?: number;
  maxNumber?: number;
  minNumber?: number;
  fillBarColor: string;
  barBgColor: string;
  fillRadius?: number;
  transitionDelayMs?: number;
  transitionDurationMs?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  variant = 'rounded',
  currentNumber = 0,
  maxNumber,
  minNumber,
  fillBarColor,
  barBgColor,
  fillRadius,
  transitionDelayMs = 0,
  transitionDurationMs,
}) => {
  const percent =
    maxNumber !== undefined ? (currentNumber / maxNumber) * 100 : 100;

  const classNames = clsx(s.root, className, s[variant]);

  const customStyle = {
    '--fill-bar-color': fillBarColor,
    '--bar-bg-color': barBgColor,
  } as React.CSSProperties;

  const fillStyle = {
    ...(typeof transitionDurationMs === 'number' && {
      transitionDuration: `${Math.round(transitionDurationMs)}ms`,
    }),
    transitionDelay: `${Math.round(transitionDelayMs)}ms`,
    borderRadius: `${fillRadius}px`,
    ...(variant === 'vertical'
      ? { height: `${percent}%` }
      : { width: `${percent}%` }),
  };

  return (
    <div className={classNames} style={customStyle}>
      {variant === 'rectangular' && (
        <span className={clsx(s.digits, s.orange)}>{currentNumber}</span>
      )}
      <div className={s.bar}>
        <div className={s.barFill} style={fillStyle}></div>
      </div>
      {variant === 'vertical' && (
        <div className={s.round}>
          <div className={s.roundFill}></div>
        </div>
      )}
      {variant === 'rectangular' && (
        <span className={s.digits}>{maxNumber}</span>
      )}
    </div>
  );
};
