import React from 'react';
import clsx from 'clsx';

import { userService } from '@app/services';
import wormHeadUrl from '@media/worm-head.png';
import { useServiceState } from '@app/common/state';

import s from './InviteTrackingWidget.module.scss';

interface InviteTrackingWidgetProps {
  className?: string;
}

export const InviteTrackingWidget: React.FC<InviteTrackingWidgetProps> = ({
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const invited = userProfile?.invitedActiveUsersCount || 0;

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.content}>
        <h5 className={s.title}>You Invited</h5>
        <div className={s.info}>
          <span className={s.amount}>{invited}</span>
          <div>FRENS</div>
        </div>
      </div>
      <img className={s.img} src={wormHeadUrl} alt="Avatar" />
    </div>
  );
};
