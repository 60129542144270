import cn from 'classnames';

import tonWalletImg from '@media/ton-wallet.png';

import s from './WalletButton.module.scss';
import { useState } from 'react';
import { BottomSheet, Button } from '@app/ui-kit';
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import { useTonConnect } from '@app/hooks';

interface WalletButtonProps {
  className?: string;
}

export const WalletButton: React.FC<WalletButtonProps> = ({ className }) => {
  const { tonConnectUI, userFriendlyAddress } = useTonConnect();
  const [isOpen, setIsOpen] = useState(false);

  const toggleBottomSheet = () => {
    setIsOpen(!isOpen);
  };

  const handleConnection = () => {
    if (userFriendlyAddress) {
      tonConnectUI.disconnect();
    } else {
      tonConnectUI.openModal();
    }
  };

  return (
    <>
      <button className={cn(s.button, className)} onClick={toggleBottomSheet}>
        <img src={tonWalletImg} className={s.walletIcon} alt="wallet" />
      </button>
      <BottomSheet
        open={isOpen}
        onClose={toggleBottomSheet}
        contentClassName={s.bottomSheet}
        bgColor={'var(--Surface-Primary)'}
      >
        <img src={tonWalletImg} alt="wallet" className={s.walletImage} />
        <h2 className={s.title}>
          {userFriendlyAddress ? 'Connected' : 'Not connected'}
        </h2>
        {userFriendlyAddress && (
          <div className={s.address}>{userFriendlyAddress}</div>
        )}
        <Button
          onClick={handleConnection}
          variant={userFriendlyAddress ? 'secondary' : 'primary'}
          className={s.connectionButton}
        >
          {userFriendlyAddress ? 'Disconnect' : 'Connect'}
        </Button>
      </BottomSheet>
    </>
  );
};
