/* eslint-disable @typescript-eslint/no-explicit-any */
type Listener = (payload: any) => void;

export class EventEmitter {
  private listeners: Record<string, Listener[]> = {};

  emit(event: string, eventPayload?: any) {
    if (!this.listeners[event]) return;

    this.listeners[event].forEach((listener) => listener(eventPayload));
  }

  on(event: string, listener: Listener) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(listener);
  }

  off(event: string, listener: Listener) {
    if (!this.listeners[event]) return;

    this.listeners[event] = this.listeners[event].filter((l) => l !== listener);
  }
}
