import cn from 'classnames';

import { VersusWssSkillData } from '@app/types';
import { VersusSkill } from '@components';

import s from './VersusSkillsGroup.module.scss';

interface VersusSkillsGroupProps {
  items: VersusWssSkillData[];
  className?: string;
}

export const VersusSkillsGroup: React.FC<VersusSkillsGroupProps> = ({
  items,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      {items.map((el, i) => (
        <VersusSkill data={el} key={el.level + el.id + i} variant="statBar" />
      ))}
    </div>
  );
};
