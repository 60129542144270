import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { BadgePoints, RewardItem } from '@components';
import { userService } from '@app/services';
import { Button } from '@app/ui-kit';
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from '@app/ui-kit/ToastNotifications';

import s from './RewardReferralWidget.module.scss';

interface RewardReferralWidgetProps {
  className?: string;
  newReferrals: number;
  newReferralsReward: number;
  yourReferralsReward: number;
  theirReferralsReward: number;
  totalReward: number;
  isDisabledClaim: boolean;
}

export const RewardReferralWidget: React.FC<RewardReferralWidgetProps> = ({
  className,
  newReferrals,
  newReferralsReward,
  yourReferralsReward,
  theirReferralsReward,
  totalReward,
  isDisabledClaim,
}) => {
  const classNames = clsx(s.root, className);

  const handleClaimReferralsRewards = useCallback(async () => {
    try {
      notifyInfo('Claim reward, please wait...');

      await userService.claimReferralsRewards();

      notifySuccess('Reward successfully received!');
    } catch (error) {
      notifyError('Error claim reward. Please try again.');

      console.error('Claim reward quest:', error);
    }
  }, []);

  return (
    <div className={classNames}>
      <p className={s.title}>Your reward</p>
      <div className={s.rewardInfo}>
        <div className={s.item}>
          <p className={s.itemDesc}>
            <span className={s.gold}>{newReferrals}</span> new referrals:
          </p>
          <BadgePoints
            size="small"
            value={newReferralsReward}
            className={s.badge}
            variant="unfilled"
          />
        </div>
        {yourReferralsReward ? (
          <div className={s.item}>
            <p className={s.itemDesc}>
              <span className={s.gold}>5%</span> from{' '}
              <span className={s.gold}>your</span> referrals:
            </p>
            <BadgePoints
              size="small"
              value={yourReferralsReward}
              className={s.badge}
              variant="unfilled"
            />
          </div>
        ) : null}

        {theirReferralsReward ? (
          <div className={s.item}>
            <p className={s.itemDesc}>
              <span className={s.gold}>2%</span> from
              <span className={s.gold}> their</span> referrals:
            </p>
            <BadgePoints
              size="small"
              value={theirReferralsReward}
              className={s.badge}
              variant="unfilled"
            />
          </div>
        ) : null}
      </div>
      <div className={s.rewardWidget}>
        <RewardItem value={totalReward} />
        {totalReward !== 0 ? (
          <Button
            onClick={handleClaimReferralsRewards}
            className={s.btn}
            size="large"
            disabled={isDisabledClaim}
          >
            Claim
          </Button>
        ) : null}
      </div>
    </div>
  );
};
