import React, { useEffect, useRef, useState } from 'react';
import { m } from 'framer-motion';
import cn from 'classnames';

import cucumber from '@media/cucumber-icon.png';

import s from './TopUpAnimation.module.scss';

interface TopUpAnimationProps {
  animateTo: string;
  destinationYOffset?: number;
  elementsAmount?: number;
  duration?: number;
  initialDelay?: number;
  initialScale?: number;
  finalScale?: number;
  className?: string;
  imageClassName?: string;
}

const TopUpAnimationComponent: React.FC<TopUpAnimationProps> = ({
  animateTo,
  destinationYOffset = 0,
  elementsAmount = 15,
  duration = 1,
  initialDelay = 0,
  initialScale = 0.5,
  finalScale = 1,
  className,
  imageClassName,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [target, setTargetPosition] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    if (animateTo) {
      const targetElement = document.querySelector(animateTo);

      if (targetElement == null) {
        console.error(`${animateTo} can't be selected with querySelector!`);

        return;
      }

      if (targetElement && containerRef.current) {
        const targetRect = targetElement.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const targetX =
          targetRect.left + targetRect.width / 2 - containerRect.left;
        const targetY = targetRect.top;

        setTargetPosition({
          x: targetX,
          y: targetY,
          width: targetRect.width,
          height: targetRect.height,
        });
      }
    }
  }, [animateTo]);

  const getRandomX = () => {
    if (containerRef.current == null || target == null) {
      return 0;
    }

    const targetCenterX = target.x;

    const randomOffset = (Math.random() - 0.5) * target.width;

    return targetCenterX + randomOffset;
  };

  return (
    <div ref={containerRef} className={cn(s.root, className)}>
      {Array.from({ length: elementsAmount }).map((_, index) => (
        <m.img
          key={index}
          initial={{ scale: initialScale, opacity: 1, x: 0, y: 0 }}
          animate={
            target
              ? {
                  x: getRandomX(),
                  y: Math.round(
                    target.y - target.height / 2 + destinationYOffset,
                  ),
                  scale: finalScale,
                  opacity: [0, 1, 0],
                  transition: {
                    duration: duration,
                    ease: 'easeOut',
                    delay: initialDelay + index * 0.05,
                  },
                  transitionEnd: {
                    display: 'none',
                  },
                }
              : {}
          }
          src={cucumber}
          className={cn(s.image, imageClassName)}
        />
      ))}
    </div>
  );
};

export const TopUpAnimation = React.memo(TopUpAnimationComponent);
