import React from 'react';
import clsx from 'clsx';

import { Button } from '@ui-kit';
import jamImg from '@media/three-jam.png';

import s from './JamIsSafe.module.scss';

interface JamIsSafeProps {
  className?: string;
  onClick: () => void;
}

export const JamIsSafe: React.FC<JamIsSafeProps> = ({ className, onClick }) => {
  return (
    <div className={clsx(s.root, className)}>
      <h4 className={s.title}>
        Your
        <span className={s.green}> $Jam is safe</span>
      </h4>
      <p className={s.desc}>
        First thing’s first! Don’t worry, your $JAM is in the Wormfare app.
      </p>

      <img className={s.img} src={jamImg} alt="jam" />

      <Button
        className={s.button}
        variant="tertiary"
        size="medium"
        children={'Open WORMFARE'}
        bgColor="#C9B69C"
        onClick={onClick}
      />
    </div>
  );
};
