import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import arrowImg from '@media/versus/hand-arrow.png';
import { getIndicatorDegree } from '@app/utils';
import { versusAudioService, versusService } from '@app/services';
import { easeOutBounce, easeOutQuad } from '@app/utils/easingFunctions';
import { useServiceState } from '@app/common/state';
import { useLoop } from '@app/hooks/useLoop';

import s from './Arrow.module.scss';

interface ArrowProps {
  className?: string;
}

export const Arrow: React.FC<ArrowProps> = ({ className }) => {
  const { indicatorPeriod, roundState, isDetectForce, savedHitTime } =
    useServiceState(versusService, [
      'indicatorPeriod',
      'roundState',
      'isDetectForce',
      'savedHitTime',
    ]);
  const imgRef = useRef<HTMLImageElement>(null);
  const currentAngleRef = useRef(-120);
  const animationFrameRef = useRef<number>();
  const tickIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isDetectForceState = roundState === 'detectForce';

  useLoop((delta) => {
    versusService.tick(delta);
  }, isDetectForceState);

  const playArrowBouncingSound = (
    angle: number,
    targetAngle: number,
    startAngle: number,
    hasPlayedSound: boolean,
  ): boolean => {
    const isAtOrCrossedTarget =
      (angle >= targetAngle && startAngle < targetAngle) ||
      (angle <= targetAngle && startAngle > targetAngle);

    if (isAtOrCrossedTarget && !hasPlayedSound) {
      versusAudioService.playSoundEffect('arrowR');

      return true;
    } else if (!isAtOrCrossedTarget) {
      return false;
    }

    return true;
  };

  const animateToAngle = useCallback(
    (
      targetAngle: number,
      duration = 500,
      easingFunction = (progress: number) => progress,
    ) => {
      const startAngle = currentAngleRef.current;
      const startTime = performance.now();
      let hasPlayedSound = false;

      const animate = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easingFunction(progress);
        const angle = startAngle + (targetAngle - startAngle) * easedProgress;

        if (roundState === 'preDetectForce') {
          hasPlayedSound = playArrowBouncingSound(
            angle,
            targetAngle,
            startAngle,
            hasPlayedSound,
          );
        }

        if (imgRef.current) {
          imgRef.current.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        }

        currentAngleRef.current = angle;

        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate);
        }
      };

      animationFrameRef.current = requestAnimationFrame(animate);
    },
    [roundState],
  );

  const updateAngle = (time: number, indicatorPeriod: number) => {
    const degree = getIndicatorDegree(time, indicatorPeriod);
    const angle = -90 + degree;

    currentAngleRef.current = angle;

    if (imgRef.current) {
      imgRef.current.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
    }
  };

  const playTickSound = (isLeft: boolean) => {
    if (isLeft) {
      versusAudioService.playSoundEffect('arrowL');
    } else {
      versusAudioService.playSoundEffect('arrowR');
    }
  };

  const startTickSoundLoop = useCallback((period: number) => {
    if (tickIntervalRef.current) {
      clearInterval(tickIntervalRef.current);
    }

    const halfPeriod = period / 2;
    let isLeft = true;

    tickIntervalRef.current = setInterval(() => {
      isLeft = !isLeft;
      playTickSound(isLeft);
    }, halfPeriod);
  }, []);

  useEffect(() => {
    const tickListener = (emitTime: number) => {
      updateAngle(
        savedHitTime !== 0 ? savedHitTime : emitTime,
        indicatorPeriod,
      );
    };

    switch (roundState) {
      case 'preDetectForce':
        animateToAngle(45, 1000, easeOutBounce);

        break;
      case 'detectForce':
        versusService.on('tick', tickListener);
        startTickSoundLoop(indicatorPeriod);

        break;
      case 'showHitForce':
        versusService.off('tick', tickListener);
        updateAngle(savedHitTime, indicatorPeriod);

        break;

      default:
        animateToAngle(-120, 1000, easeOutQuad);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      versusService.off('tick', tickListener);

      if (tickIntervalRef.current) {
        clearInterval(tickIntervalRef.current);
      }
    };
  }, [
    roundState,
    indicatorPeriod,
    savedHitTime,
    startTickSoundLoop,
    animateToAngle,
  ]);

  useEffect(() => {
    if (isDetectForce) {
      console.log('Hit angle:', currentAngleRef.current);
      versusService.setHitAngle(currentAngleRef.current);
    }
  }, [isDetectForce]);

  return (
    <img
      className={clsx(s.root, className)}
      src={arrowImg}
      alt=""
      ref={imgRef}
    />
  );
};
