import React, { useMemo } from 'react';
import clsx from 'clsx';
import { formatEther } from '@ethersproject/units';

import { BadgePoints } from '@components';
import defaultUserAvatar from '@media/avatar.png';

import styles from './FriendItem.module.scss';

interface FriendItemProps {
  className?: string;
  fullName: string;
  avatarUrl?: string;
  bonus?: number;
  isPending: boolean;
}

export const FriendItem: React.FC<FriendItemProps> = ({
  className,
  fullName,
  avatarUrl,
  bonus = 0,
  isPending,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.imgWrap}>
        <img
          className={styles.img}
          src={(avatarUrl || defaultUserAvatar).replace(
            /https:\/dev/,
            'https://dev',
          )}
          alt="Avatar"
          width={32}
          height={32}
        />
      </div>
      <div className={styles.wrap}>
        <p className={styles.userName}>{fullName}</p>
        {!isPending && (
          <BadgePoints
            variant="unfilled"
            size="small"
            value={bonus}
            className={styles.badge}
          />
        )}
        {isPending && (
          <BadgePoints
            variant="pending"
            size="small"
            value={'Pending'}
            className={styles.badge}
          />
        )}
      </div>
    </div>
  );
};
