/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'reflect-metadata';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setup } from './setup';
import { ToastNotifications } from './ui-kit/ToastNotifications';
import { notifyError } from '@ui-kit/ToastNotifications';
import { isMaintainanceError } from './utils';
import { GlobalNavigator } from './components';

import 'swiper/css';
import 'swiper/css/navigation';
import './styles/index.scss';

const AppWrapper = () => {
  window.addEventListener('error', (event) => {
    notifyError(
      `Something went wrong!\n${event.message}\nPlease, try to reload the page.`,
    );
  });

  window.addEventListener('unhandledrejection', (event) => {
    if (!isMaintainanceError(event)) {
      notifyError(
        `Something went wrong!\n${event.reason}\nPlease, try to reload the page.`,
      );
    }
  });

  return (
    <>
      <App />
      <ToastNotifications />
    </>
  );
};

setup();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <GlobalNavigator />
    <AppWrapper />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
