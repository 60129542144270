import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { Button } from '@app/ui-kit';
import { motion } from 'framer-motion';
import { versusService } from '@app/services';

import s from './MatchMakingStatus.module.scss';

interface MatchMakingStatusProps {
  mode: 'looking' | 'found';
  className?: string;
  onCancel: () => void;
}

export const MatchMakingStatus: React.FC<MatchMakingStatusProps> = ({
  mode,
  className,
  onCancel,
}) => {
  const isLooking = mode === 'looking';
  const [dots, setDots] = useState('');
  const [startTime, setStartTime] = useState<number | null>(null);
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [isCanceled, setIsCanceled] = useState(false);
  const lookingText = `Looking for opponent`;
  const foundText = 'Opponent found!';
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (isLooking) {
      const dotsInterval = setInterval(() => {
        setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
      }, 500);

      return () => clearInterval(dotsInterval);
    }
  }, [isLooking]);

  useEffect(() => {
    if (isLooking && !startTime) {
      setStartTime(Date.now());
    }

    if (isLooking && startTime) {
      timerRef.current = window.setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);

        setTimeElapsed(elapsedTime);
      }, 1000);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isLooking, startTime]);

  useEffect(() => {
    const startTime = Date.now() - timeElapsed * 1000;

    if (isLooking) {
      timerRef.current = window.setInterval(() => {
        setTimeElapsed(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isLooking, timeElapsed]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleCancel = () => {
    setIsCanceled(true);
    onCancel();
  };

  return (
    <div className={cn(s.root, className)}>
      {!isLooking && (
        <motion.h2
          initial={{ scale: 2, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 130,
            damping: 10,
            mass: 1,
          }}
          className={s.title}
        >
          {foundText}
          <motion.span></motion.span>
        </motion.h2>
      )}

      {isLooking && (
        <motion.div
          initial={{ opacity: 0, y: 80 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: 'spring',
            stiffness: 160,
            damping: 10,
            mass: 1,
            delay: 0.4,
          }}
          className={s.wrapper}
        >
          <h2 className={s.title}>
            <span>{lookingText}</span>
            <span className={s.dots}>{dots}</span>
          </h2>
          <div className={s.timer}>
            <span>Time elapsed:</span>
            <span>{formatTime(timeElapsed)}</span>
          </div>
          <Button
            onClick={handleCancel}
            primaryVariantColor="grey"
            className={s.button}
            disabled={isCanceled}
          >
            {isCanceled ? 'Cancelling..' : 'Cancel'}
          </Button>
        </motion.div>
      )}
    </div>
  );
};
