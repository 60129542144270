import { versusAudioService } from '@app/services';
import { ReactNode, useCallback, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

interface VersusMusicPlayerProps {
  children: ReactNode;
}

export const VersusMusicPlayer: React.FC<VersusMusicPlayerProps> = ({
  children,
}) => {
  const location = useLocation();

  const playAudio = useCallback(() => {
    versusAudioService.playGeneralTracks();
  }, []);

  const stopAudio = useCallback(() => {
    versusAudioService.pauseGeneralMusic(1000);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const isAudioPage = versusAudioService.pagesWithAudio.some((path) =>
      matchPath({ path, end: true }, currentPath),
    );

    if (isAudioPage) {
      playAudio();
    } else {
      stopAudio();
    }
  }, [location, playAudio, stopAudio]);

  return <>{children}</>;
};
