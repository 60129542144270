import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import cn from 'classnames';

import { useSpineAnimation } from '@app/hooks';

import s from './MatchMakingWorm.module.scss';

interface MatchMakingWormProps {
  assetsUrl: string | undefined;
  charCode: string | undefined;
  accessoryName: string | undefined;
  animationName: string | null;
  className?: string;
}

export const MatchMakingWorm: React.FC<MatchMakingWormProps> = ({
  assetsUrl,
  charCode,
  accessoryName,
  animationName,
  className,
}) => {
  const { containerRef, isLoaded, changeAnimation, dispose } =
    useSpineAnimation({
      assetsUrl: assetsUrl,
      charCode: charCode,
      accessoryName: accessoryName,
      config: {
        preserveDrawingBuffer: false,
        animation: 'matchmaking-idle',
        alpha: true,
        viewport: { x: -130, y: -250, width: 450, height: 1600 },
      },
    });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (animationName && isLoaded) {
      changeAnimation(animationName, { force: true, loop: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationName, isLoaded]);

  return (
    <>
      <div className={clsx(s.root, className)}>
        <div
          className={cn(s.worm, { [s.loaded]: isLoaded })}
          ref={containerRef}
        />
      </div>
    </>
  );
};
