import cn from 'classnames';

import { CoinsIndicator } from '../CoinsIndicator';

import s from './CucumberBalance.module.scss';

interface CucumberBalanceProps {
  balance: number;
  animate: boolean;
  animateDuration?: number;
  zIndex?: number;
  className?: string;
}

export const CucumberBalance: React.FC<CucumberBalanceProps> = ({
  balance,
  animate,
  animateDuration = 1.5,
  zIndex,
  className,
}) => {
  return (
    <div className={cn(s.root, className)} style={{ zIndex }}>
      <div id="cucumberBalanceAnimationTarget" className={s.animationTarget} />
      <CoinsIndicator
        variant="cucumber"
        value={balance}
        animateDuration={animateDuration}
        animateOnChange={animate}
        fontSize={48}
      />
    </div>
  );
};
