import React from 'react';
import clsx from 'clsx';

import { BadgePoints, ProgressBar } from '@components';

import s from './ProgressItem.module.scss';
import {} from '@app/types';

interface ProgressItemProps {
  className?: string;
  reward?: number;
  numberOfFriends?: number;
  minNumberOfFriends: number;
  maxNumberOfFriends: number;
}

export const ProgressItem: React.FC<ProgressItemProps> = ({
  className,
  reward,
  numberOfFriends,
  minNumberOfFriends,
  maxNumberOfFriends,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <BadgePoints
        size="small"
        value={reward || 0}
        className={s.badge}
        variant="default"
      />
      <p className={s.descProgress}>
        Invite <span>{maxNumberOfFriends} </span>
        FREN
      </p>
      <ProgressBar
        maxNumber={maxNumberOfFriends}
        currentNumber={numberOfFriends}
        minNumber={minNumberOfFriends}
        fillBarColor="#FFC166"
        barBgColor="#413730"
        variant="rectangular"
        className={s.bar}
      />
    </div>
  );
};
