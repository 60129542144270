import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { VersusAccessory, VersusPriceType } from '@app/types';
import {
  ButtonPurchaseStars,
  ShopAccessoryBonus,
  VersusPriceTag,
  Web3PurchaseStatus,
} from '@components';
import { Button } from '@app/ui-kit';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';
import { useTonClient, useTonConnect } from '@app/hooks';

import s from './PurchaseAccessoryBlock.module.scss';

interface PurchaseAccessoryBlockProps {
  itemId: string;
  itemName: string;
  price: number;
  priceFull?: number;
  priceStars?: number;
  priceTon: string | undefined;
  priceType: VersusPriceType;
  abilities: VersusAccessory['abilities'];
  className?: string;
}

export const PurchaseAccessoryBlock: React.FC<PurchaseAccessoryBlockProps> = ({
  itemId,
  itemName,
  price,
  priceFull,
  priceTon,
  priceStars,
  priceType,
  abilities,
  className,
}) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [purchaseType, setPurchaseType] = useState<VersusPriceType>();

  const { tonConnectUI, wallet } = useTonConnect();
  const { client } = useTonClient();
  const userId = userProfile?.id;
  const balance = userProfile?.score ?? 0;
  const purchaseDisabled =
    isLoading || (priceType === VersusPriceType.Tomato && balance < price);

  const handlePurchase = async (purchaseType: VersusPriceType) => {
    try {
      if (purchaseType === VersusPriceType.Tomato) {
        setIsLoading(true);

        await versusCharacterService.purchaseAccessoryWithTomato(itemId);
        navigate('/shop/accessories');
      }

      if (purchaseType === VersusPriceType.Ton) {
        if (!wallet?.account.address) {
          await tonConnectUI.openModal();

          return;
        }

        setPurchaseType(purchaseType);

        setIsLoading(true);
        await versusCharacterService.purchaseWithTon({
          wallet,
          client,
          tonConnectUI,
          tgUserId: userId,
          shopItemCode: itemId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          shopItemPrice: priceTon!,
          itemType: 'accessory',
          onTxSent: () => setPurchaseCompleted(true),
        });
      }

      if (purchaseType === VersusPriceType.Stars) {
        setPurchaseType(purchaseType);

        await versusCharacterService.purchaseItemWithStars({
          shopItemCode: itemId,
          shopItemPrice: price,
          itemType: 'worm',
          onInvoicePaid: () => setPurchaseCompleted(true),
          onItemReceived: () => setPurchaseCompleted(false),
        });
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <div className={cn(s.root, className)}>
      <h3 className={s.name}>{itemName}</h3>
      <VersusPriceTag
        price={price}
        priceTon={priceTon}
        priceFull={priceFull}
        priceType={priceType}
      />
      {abilities.map((bonus, i) => (
        <ShopAccessoryBonus
          bonus={bonus}
          className={s.bonus}
          key={bonus.bonusType + i}
        />
      ))}
      {priceType === VersusPriceType.Tomato && (
        <Button
          onClick={() => handlePurchase(VersusPriceType.Tomato)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {isLoading ? 'Processing...' : 'Purchase'}
        </Button>
      )}
      {(priceType === VersusPriceType.Ton && isLoading) ||
      priceType === VersusPriceType.Tomato ? null : (
        <Button
          onClick={() => handlePurchase(VersusPriceType.Ton)}
          disabled={purchaseDisabled}
          className={s.button}
        >
          {!wallet?.account.address ? 'Connect Wallet' : 'Purchase using TON'}
        </Button>
      )}
      {priceStars && priceStars > 0 && !purchaseCompleted && (
        <ButtonPurchaseStars
          onClick={() => handlePurchase(VersusPriceType.Stars)}
          disabled={purchaseDisabled}
          stars={priceStars || 0}
        />
      )}
      <Web3PurchaseStatus isCompleted={purchaseCompleted} type={purchaseType} />
    </div>
  );
};
