import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './polyfills';

import { AnimatePresence } from 'framer-motion';
import {
  BoostersPage,
  LeaguePage,
  GeneralLayout,
  SettingsPage,
  TomatoHowItWorkPage,
  TomatoQuestsPage,
  FriendsPage,
  ShopPage,
  SlapVersusPage,
  MatchMakingPage,
  VersusBattleResultPage,
  SkillsPage,
  LeagueProgressionPage,
  LeagueLeaderboardPage,
  EquipPage,
  HomePage,
  LeaderboardComingPage,
  SelectWormPage,
  ShopWormPreviewPage,
  ShopAccessoryPreviewPage,
  ShopItemPreviewPage,
} from '@app/pages';
import { LazyMotion, domAnimation } from 'framer-motion';
import { apiService, configService, userService } from './services';
import {
  DesktopStubScreen,
  Preloader,
  TomatoRewardBottomSheet,
  Maintenance,
} from '@components';
import { isMobile } from '@app/utils';
import { usePreloadResources } from './hooks';
import * as Sentry from '@sentry/react';
import { useAuth } from './hooks/useAuth';
import { useServiceState } from './common/state';
import { StartParams, parseStartParams } from './utils/parseStartParams';
import { isAxiosError } from 'axios';
import { notifySuccess, notifyError } from '@ui-kit/ToastNotifications';
import { VersusBattlePage } from './pages/versus-battle';
import { VersusMusicPlayer } from './components/VersusMusicPlayer/VersusMusicPlayer';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const startParams = parseStartParams<StartParams>();

const refUserId = parseInt(startParams?.ref || '');
const walletAddress = startParams?.address || '';
const walletAddressHash = startParams?.hash || '';

const App: React.FC = () => {
  const { isLoading } = useAuth(refUserId);
  const location = useLocation();
  const { allResourcesLoaded, progress } = usePreloadResources(isLoading);

  const { maintainSession } = useServiceState(apiService);

  const handleError = (
    error: unknown,
    componentStack?: string,
    eventId?: string,
  ) => {
    if (error instanceof Error) {
      if (error.message.includes('401')) {
        console.warn('Unauthorized access - 401 error');
      } else {
        notifyError(
          `Something went wrong!\n${error.message}\nPlease, try to reload the page.`,
          { autoClose: 5000 },
        );
      }
    } else {
      console.error('An unknown error occurred:', error);
    }
  };

  useEffect(() => {
    if (walletAddress && walletAddressHash) {
      userService
        .connectWallet(walletAddress, walletAddressHash)
        .then(() => {
          notifySuccess('Wallet connected successfully!');
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            notifyError(error.response?.data.message || error.message, {
              autoClose: 5000,
            });
          } else {
            notifyError(
              `Something went wrong!\n${error.message}\nPlease, try to reload the page.`,
              { autoClose: 5000 },
            );
          }
        });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.Telegram?.WebApp.expand();
      window.Telegram.WebApp.disableVerticalSwipes();
    }, 0);
  }, []);

  if (!isMobile() && !configService.isDev) {
    return <DesktopStubScreen />;
  }

  if ((isLoading || !allResourcesLoaded) && !maintainSession) {
    return <Preloader progress={progress} />;
  }

  if (maintainSession) {
    return <Maintenance timestamp={maintainSession} />;
  }

  return (
    <TonConnectUIProvider
      manifestUrl="https://clicker.wormfare.com/tonconnect-manifest.json"
      uiPreferences={{
        colorsSet: {
          [THEME.DARK]: {
            accent: 'var(--text-primary)',
            background: {
              primary: 'var(--Surface-BG)',
              secondary: 'var(--Surface-Secondary)',
              segment: 'var(--text-primary)',
              tint: 'var(--Surface-Primary)',
            },
          },
        },
      }}
      actionsConfiguration={{
        returnStrategy: 'back',
        twaReturnUrl: configService.isLocal
          ? undefined
          : (`${configService.botLink}/start` as `${string}://${string}`),
      }}
    >
      <LazyMotion features={domAnimation}>
        <TomatoRewardBottomSheet />
        {/* <DebugInfoPopup /> */}
        {/* <AutoBotBottomSheet /> */}
        <VersusMusicPlayer>
          <AnimatePresence mode="wait">
            <Sentry.ErrorBoundary onError={handleError}>
              <SentryRoutes location={location} key={location.pathname}>
                <Route path="/" element={<HomePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/league" element={<LeaguePage />} />
                <Route path="/battle" element={<VersusBattlePage />} />

                <Route path="/play" element={<SlapVersusPage />} />
                <Route path="/match-making" element={<MatchMakingPage />} />
                <Route
                  path="/battle-result"
                  element={<VersusBattleResultPage />}
                />
                <Route path="/skills/:mode" element={<SkillsPage />} />
                <Route
                  path="/league-progression"
                  element={<LeagueProgressionPage />}
                />
                <Route
                  path="/league-leaderboard"
                  element={<LeaderboardComingPage />}
                />
                <Route path="/equip" element={<EquipPage />} />
                <Route path="/select-worm" element={<SelectWormPage />} />
                <Route
                  path="/shop-worm-preview/:code"
                  element={<ShopWormPreviewPage />}
                />
                <Route
                  path="/shop-accessory-preview/:id"
                  element={<ShopAccessoryPreviewPage />}
                />
                <Route
                  path="/shop-item-preview/:id"
                  element={<ShopItemPreviewPage />}
                />

                <Route element={<GeneralLayout />}>
                  <Route
                    path="/tomato-how-it-work"
                    element={<TomatoHowItWorkPage />}
                  />
                  <Route path="/shop/:tab" element={<ShopPage />} />
                  <Route path="/friends" element={<FriendsPage />} />
                  <Route path="/quests" element={<TomatoQuestsPage />} />
                  <Route path="/boosters" element={<BoostersPage />} />
                  <Route path="*" element={<HomePage />} />
                </Route>
              </SentryRoutes>
            </Sentry.ErrorBoundary>
          </AnimatePresence>
        </VersusMusicPlayer>
      </LazyMotion>
    </TonConnectUIProvider>
  );
};

export default App;
