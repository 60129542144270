import React from 'react';
import clsx from 'clsx';

import s from './TomatoRewardCard.module.scss';

interface TomatoRewardCardProps {
  className?: string;
  icon: string;
  title: string;
  desc?: React.ReactNode;
}

export const TomatoRewardCard: React.FC<TomatoRewardCardProps> = ({
  className,
  icon,
  title,
  desc,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <img src={icon} className={s.img} alt="icon" />

      <div className={s.contentWrap}>
        <h4 className={s.title}>{title}</h4>
        <p className={s.desc}>{desc}</p>
      </div>
    </div>
  );
};
