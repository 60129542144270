import { DIContainer } from '@app/common/di';
import { UserService } from './UserService';
import { GameService } from './GameService';
import { ConfigService } from './ConfigService';
import { SquadService } from './SquadService';
import { LeagueService } from './LeagueService';
import { BotHunterService } from './BotHunterService';
import { QuestService } from './QuestService';
import { AuctionService } from './AuctionService';
import { AnalitycsService } from './AnalyticsService';
import { CacheService } from './CacheService';
import { ShopService } from './ShopService';
import { СheatСodeService } from './СheatСodeService';
import { SoundService } from './SoundService';
import { ApiService } from './ApiService';
import { AuthService } from './AuthService';
import { AirdropService } from './AirdropService';
import { RandomSlapSoundService } from './RandomSlapSoundService';
import { VersusService } from './VersusService';
import { OnboardingService } from './OnboardingService';
import { SocketService } from './SocketService';
import { VersusLeagueService } from './VersusLeagueService';
import { VersusAudioService } from './VersusAudioService';
import { VersusCharacterService } from './VersusCharacterService';

// export { soundService } from './SoundService';
export const cacheService = DIContainer.resolve(CacheService);
export const authService = DIContainer.resolve(AuthService);
export const apiService = DIContainer.resolve(ApiService);
export const userService = DIContainer.resolve(UserService);
export const configService = DIContainer.resolve(ConfigService);
export const gameService = DIContainer.resolve(GameService);
export const squadService = DIContainer.resolve(SquadService);
export const leagueService = DIContainer.resolve(LeagueService);
export const botHunterService = DIContainer.resolve(BotHunterService);
export const questService = DIContainer.resolve(QuestService);
export const auctionService = DIContainer.resolve(AuctionService);
export const analitycsService = DIContainer.resolve(AnalitycsService);
export const shopService = DIContainer.resolve(ShopService);
export const cheatСodeService = DIContainer.resolve(СheatСodeService);
export const soundService = DIContainer.resolve(SoundService);
export const airdropService = DIContainer.resolve(AirdropService);
export const randomSlapSoundService = DIContainer.resolve(
  RandomSlapSoundService,
);
export const versusService = DIContainer.resolve(VersusService);
export const onboardingService = DIContainer.resolve(OnboardingService);
export const socketService = DIContainer.resolve(SocketService);
export const versusLeagueService = DIContainer.resolve(VersusLeagueService);
export const versusAudioService = DIContainer.resolve(VersusAudioService);
export const versusCharacterService = DIContainer.resolve(
  VersusCharacterService,
);
