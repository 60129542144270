import { MakeObservable, observable } from '@common/state';
import { Injectable } from '@app/common/di';

@Injectable()
@MakeObservable
export class VersusAudioService {
  private context: AudioContext;
  private soundBuffers: Record<string, AudioBuffer> = {};
  private currentSource: AudioBufferSourceNode | null = null;
  private gainNode: GainNode | null = null;
  private currentPlaybackTime = 0;
  private startTime = 0;
  private currentTrackIndex = 0;

  constructor() {
    this.context = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 44100,
    });
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.context.destination);
  }

  @observable
  public canPlayAudio = true;

  @observable
  public isPlaying = false;

  private readonly audioFormat = '.mp3';

  public readonly pagesWithAudio = [
    '/battle',
    '/play',
    '/match-making',
    '/battle-result',
    '/skills/:mode',
    '/league-progression',
    '/league-leaderboard',
    '/equip',
  ];

  public readonly audioPath = '/sounds/versus/';
  public readonly musicPath = `${this.audioPath}music/`;
  public readonly eventsMusicPath = `${this.audioPath}event-music/`;

  public readonly musicTracks = [];

  public readonly eventMusicTracks = {};

  public readonly effects = {
    arrowL: 'arrow-left',
    arrowR: 'arrow-right',
    buttonClick: 'button-click',
    cucumbersTopUp: 'cucumbers-top-up',
    maxSlapIncoming: 'max-slap-incoming',
    maxSlapOutgoing: 'max-slap-outgoing',
    midSlapIncoming: 'mid-slap-incoming',
    midSlapOutgoing: 'mid-slap-outgoing',
    missSlapIncoming: 'miss-slap-incoming',
    missSlapOutgoing: 'miss-slap-outgoing',
    sectorTurn1: 'sector-turn-1',
    sectorTurn2: 'sector-turn-2',
    sectorTurnFight: 'sector-turn-fight',
    sectorTurnSuccess: 'sector-turn-success',
    sectorTurnTimeout: 'sector-turn-timeout',
    skillUpgrade: 'skill-upgrade',
    timeoutSlapZero: 'timeout-slap-zero-pop',
  };

  public async preloadAudio() {
    const soundsToLoad = [
      // TODO uncomment when music feature implemented
      // ...this.musicTracks,
      // ...Object.values(this.eventMusicTracks),
      ...Object.values(this.effects).map(
        (effect) => `${this.audioPath}${effect}${this.audioFormat}`,
      ),
    ];

    await Promise.all(soundsToLoad.map((url) => this.loadSoundBuffer(url)));
  }

  private async loadSoundBuffer(url: string): Promise<void> {
    if (this.soundBuffers[url]) return;

    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.context.decodeAudioData(arrayBuffer);

      this.soundBuffers[url] = audioBuffer;
    } catch (error) {
      console.error(`Failed to load sound: ${url}`, error);
    }
  }

  playGeneralTracks() {
    // TODO
  }

  pauseGeneralMusic(duration = 1000) {
    // TODO
  }

  public playSoundEffect(effectName: keyof typeof this.effects) {
    if (!this.canPlayAudio) {
      return;
    }

    const effectPath = `${this.audioPath}${this.effects[effectName]}${this.audioFormat}`;

    const soundBuffer = this.soundBuffers[effectPath];

    if (!soundBuffer) {
      console.error(`Sound effect not found: ${effectName}`);

      return;
    }

    const effectSource = this.context.createBufferSource();

    effectSource.buffer = soundBuffer;

    effectSource.connect(this.gainNode as GainNode);
    effectSource.start();
  }
}
