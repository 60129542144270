import { Injectable } from '@common/di';
import { ApiService } from '../ApiService';
import { MakeObservable, observable } from '@app/common/state';
import type { Airdrop, AirdropRaw } from '@app/types/airdrop';
import { mapAirdropRawToAirdrop } from './mapper';
import { UserService } from '../UserService';

@Injectable()
@MakeObservable
export class AirdropService {
  @observable
  public isAirdropLoading = false;

  @observable
  public airdrop: Airdrop | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly userService: UserService,
  ) {}

  async getAirdrop() {
    this.isAirdropLoading = true;

    const airdrop = await this.apiService.get<AirdropRaw>('/claim');

    this.airdrop = mapAirdropRawToAirdrop(airdrop);

    this.isAirdropLoading = false;
  }

  async claim(type: string, spendAmount?: number) {
    const body: { type: string; spendAmount?: number } = {
      type,
    };

    if (spendAmount) {
      body.spendAmount = spendAmount;
    }

    const airdrop = await this.apiService.post<AirdropRaw>('/claim', body);

    this.airdrop = mapAirdropRawToAirdrop(airdrop);
    await this.userService.fetchProfile();
  }
}
