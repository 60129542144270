import { MakeObservable, observable } from '@common/state';
import { CacheService } from '../CacheService';
import { Injectable } from '@app/common/di';

@Injectable()
@MakeObservable
export class SoundService {
  context: AudioContext;
  soundBuffers: Record<string, AudioBuffer> = {};

  @observable
  public muted: boolean;

  @observable
  public isSaving = false;

  constructor(private readonly cacheService: CacheService) {
    this.context = new AudioContext();

    const cachedMuteState = this.cacheService.get('muteState');

    if (typeof cachedMuteState === 'boolean') {
      this.muted = cachedMuteState;
    } else {
      this.muted = false;
    }
  }

  async loadSound(url: string) {
    const cachedSoundBase64 = this.cacheService.get(`sound_${url}`) as string;

    if (cachedSoundBase64) {
      const binaryString = atob(cachedSoundBase64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return bytes.buffer;
    }

    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();

    const bytes = new Uint8Array(arrayBuffer);
    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    const base64String = btoa(binary);

    this.cacheService.set(`sound_${url}`, base64String);

    return arrayBuffer;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throttle(func: any, delay: number) {
    let lastCall = 0;

    return function (...args: unknown[]) {
      const now = new Date().getTime();

      if (now - lastCall >= delay) {
        func(...args);
        lastCall = now;
      }
    };
  }

  playSound = this.throttle(async (soundUrl: string) => {
    try {
      if (this.muted) {
        return;
      }

      const gainNode = this.context.createGain();
      let audioBuffer = this.soundBuffers[soundUrl];

      if (!audioBuffer) {
        const arrayBuffer = await this.loadSound(soundUrl);

        audioBuffer = await this.context.decodeAudioData(arrayBuffer);
        this.soundBuffers[soundUrl] = audioBuffer;
      }

      await this.context.resume();

      const source = this.context.createBufferSource();

      source.buffer = audioBuffer;

      source.connect(gainNode).connect(this.context.destination);
      source.start();
    } catch (err) {
      console.error(err);
    }
  }, 50);

  muteAllSound(muted?: boolean) {
    this.muted = typeof muted !== 'undefined' ? muted : !this.muted;

    if (this.muted) {
      this.context.suspend();
    } else {
      this.context.resume();
    }

    this.cacheService.set('muteState', this.muted);
  }
}
