import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

export const GeneralLayout = () => {
  return (
    <div className={styles.layout}>
      <Outlet />
    </div>
  );
};
