import React from 'react';
import clsx from 'clsx';

import { Loader } from '@ui-kit';
import checkMark from '@icons/check-mark.svg';
import lastDayIcon from '@media/last-day-mark.png';

import s from './DayChecklistItem.module.scss';

interface DayChecklistItemProps {
  className?: string;
  numberOfDay: number;
  isFinalDay?: boolean;
  isCompleted?: boolean;
  isLoading?: boolean;
}

export const DayChecklistItem: React.FC<DayChecklistItemProps> = ({
  className,
  numberOfDay,
  isCompleted = false,
  isLoading = false,
  isFinalDay = false,
}) => {
  return (
    <div className={clsx(s.root, className)}>
      <p className={s.text}>Day {numberOfDay}</p>

      {isCompleted && (
        <div className={s.completed}>
          <img className={s.completed} src={checkMark} alt="check mark" />
        </div>
      )}
      {!isCompleted && !isFinalDay && (
        <div className={s.mark}>{isLoading && <Loader size="small" />}</div>
      )}
      {!isCompleted && isFinalDay && (
        <div className={s.iconContainer}>
          <img className={s.lastDayIcon} src={lastDayIcon} alt="icon" />
        </div>
      )}
    </div>
  );
};
