import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

import s from './LeagueRankName.module.scss';

interface LeagueRankNameProps {
  rankName: string;
  navDirection: number;
  transitionDurationSeconds?: number;
  className?: string;
}

export const LeagueRankName: React.FC<LeagueRankNameProps> = ({
  rankName,
  navDirection,
  transitionDurationSeconds = 0.5,
  className,
}) => {
  return (
    <AnimatePresence initial={false} custom={navDirection} mode="popLayout">
      <m.h2
        custom={navDirection}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: transitionDurationSeconds, type: 'tween' }}
        key={rankName}
        className={cn(s.root, className)}
      >
        {rankName}
      </m.h2>
    </AnimatePresence>
  );
};

const variants = {
  enter: (direction: number) => {
    return {
      opacity: 0,
      x: direction > 0 ? '100vw' : '-100vw',
    };
  },
  center: {
    opacity: 1,
    x: 0,
  },
  exit: (direction: number) => {
    return {
      opacity: 0,
      x: direction < 0 ? '100vw' : '-100vw',
    };
  },
};
