import {
  Page,
  BackButton,
  VersusHeader,
  InviteTrackingWidget,
  HomeWidgetSlapBeta,
  HomeWidgetPartnerEngage,
  DayChecklist,
  HomeBuyWormsCard,
} from '@components';

import { useHideBackButton } from '@app/hooks';
import { ReactComponent as TonIcon } from '@icons/ton-icon.svg';

import s from './HomePage.module.scss';

export const HomePage = () => {
  useHideBackButton();

  return (
    <Page className={s.root}>
      <BackButton onClick={() => window.Telegram.WebApp.close()} />
      <VersusHeader />
      <div className={s.content}>
        <div className={s.padding}>
          <HomeBuyWormsCard />
        </div>
        <div className={s.tournaments}>
          <h1 className={s.title}>Slap Tournaments</h1>
          <div className={s.description}>
            Win
            <div className={s.tag}>
              <TonIcon className={s.tonIcon} />
              <span>30</span>
            </div>
            Every day
          </div>
          <p className={s.coming}>Coming soon...</p>
        </div>
        <div className={s.padding}>
          <HomeWidgetSlapBeta />
          <HomeWidgetPartnerEngage />
          <InviteTrackingWidget />
          <DayChecklist variant="widget" className={s.streak} />
        </div>
      </div>
    </Page>
  );
};
