import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cn from 'classnames';

import { BackButton, ImageLoader, Page } from '@app/components';
import { ShopPageParams } from '../shop/ShopPage';
import { PurchaseItemBlock } from '@app/components/PurchaseItemBlock';
import { useServiceState } from '@app/common/state';
import { versusCharacterService } from '@app/services';

import s from './ShopItemPreviewPage.module.scss';

export const ShopItemPreviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { shopItems } = useServiceState(versusCharacterService, ['shopItems']);
  const { id } = useParams<{ id: string }>();
  const [imageLoaded, setImageLoaded] = useState(false);

  const item = shopItems?.find((item) => item.code === id);

  useEffect(() => {
    if (!id || !item) {
      navigate('/shop/cucumbers');
    }
  }, [id, item, navigate]);

  if (!id || !item) {
    return null;
  }

  const { assetsUrl, code, name, priceTon, price, priceFull, priceStars } =
    item;

  const image = versusCharacterService.generateItemImage(assetsUrl, code);

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton
        onClick={() => navigate(`/shop/${ShopPageParams.cucumbers}`)}
      />
      <ImageLoader
        src={image}
        alt={name}
        className={cn(s.image, { [s.loaded]: imageLoaded })}
        onLoad={setImageLoaded}
      />
      <PurchaseItemBlock
        itemId={code}
        itemName={name}
        price={price}
        priceTon={priceTon}
        priceStars={priceStars}
        priceFull={priceFull}
      />
    </Page>
  );
};
