import cn from 'classnames';

import { Button } from '@app/ui-kit';

import s from './BottomButton.module.scss';
import { isIOS } from '@app/utils';

interface BottomButtonProps {
  label?: string;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

export const BottomButton: React.FC<BottomButtonProps> = ({
  label = 'Next',
  disabled,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn(s.root, className)}
      style={{
        paddingBottom: isIOS() ? '36px' : '24px',
      }}
    >
      <Button className={s.button} disabled={disabled} onClick={onClick}>
        {label}
      </Button>
    </div>
  );
};
