import cn from 'classnames';

import { RankProgressBar, RankBadge } from '@components';

import s from './RankWithProgress.module.scss';

interface RankWithProgressProps {
  level: number | undefined;
  currentProgress: number | undefined;
  showProgressValues: boolean;
  variant: 'menu' | 'battleResult' | 'leagueProgress';
  showBar?: boolean;
  min?: number;
  max?: number;
  zIndex?: number;
  className?: string;
  rankImage: string | undefined;
  transitionDurationsMs?: number;
  onClick?: () => void;
}

export const RankWithProgress: React.FC<RankWithProgressProps> = ({
  level = 0,
  currentProgress = 0,
  showProgressValues,
  variant,
  min = 0,
  max = 7,
  showBar = true,
  zIndex,
  rankImage,
  transitionDurationsMs,
  className,
  onClick,
}) => {
  return (
    <button
      className={cn(s.root, s[variant], className)}
      style={{ zIndex }}
      onClick={onClick}
    >
      {showBar && (
        <RankProgressBar
          currentProgress={currentProgress}
          showValues={showProgressValues}
          transitionDurationMs={transitionDurationsMs}
          variant={variant}
          min={min}
          max={max}
          className={s.bar}
        />
      )}
      <RankBadge level={level} rankImage={rankImage} className={s.badge} />
    </button>
  );
};
