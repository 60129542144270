import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { HeroSection, Page } from '@components';
import { BackButton } from '@components';
import { Tabs } from '@ui-kit';
import { AccessoriesTab } from './tabs';
import { BoostersTab } from './tabs';
import { shopService } from '@services';

import rocketImg from '@media/rocket.png';

import s from './BoostersPage.module.scss';

type TabKey = 'boosters' | 'accessories';

export const BoostersPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<TabKey>(
    (searchParams.get('tab') as TabKey) || 'boosters',
  );

  useEffect(() => {
    shopService.fetchShop();
  }, []);

  const boosterDescLink = () => {
    window.Telegram?.WebApp?.openLink(
      'https://wormfare.notion.site/A-full-guide-on-Slap-4991283c32384a658bb6922b0a834ae4',
    );
  };

  return (
    <Page className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <HeroSection
        className={s.hero}
        title="Boost Your Slap"
        desc="Add some power"
        img={rocketImg}
        imgMargin={'40px 0 8px 0'}
        imgHeight={172}
        imgWidth={164}
      />
      <div>
        <Tabs
          initialActiveTab={currentTab}
          className={s.tabs}
          variant="secondary"
          tabs={[
            { key: 'boosters', label: 'Boosters' },
            { key: 'accessories', label: 'Accessories' },
          ]}
          onTabChange={setCurrentTab}
        />
        {currentTab === 'boosters' && <BoostersTab />}
        {currentTab === 'accessories' && <AccessoriesTab />}
      </div>
    </Page>
  );
};
