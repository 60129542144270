import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { BottomButton, ShopForCard } from '@components';
import { useSpineAnimation } from '@app/hooks';
import { WormdrobeItem } from './WormdrobeItem';
import { VersusAccessory } from '@app/types';
import { useServiceState } from '@app/common/state';
import { userService, versusCharacterService } from '@app/services';

import s from './Wormdrobe.module.scss';

interface WormdrobeProps {
  className?: string;
}

export const Wormdrobe: React.FC<WormdrobeProps> = ({ className }) => {
  const navigate = useNavigate();
  const { userProfile } = useServiceState(userService, ['userProfile']);

  const userAccessory = userProfile?.versus?.character.accessories?.[0] ?? null;
  const [selectedItem, setSelectedItem] = useState<VersusAccessory | null>(
    userAccessory,
  );

  const [isLoading, setIsLoading] = useState(false);

  const { userAccessories } = useServiceState(versusCharacterService, [
    'userAccessories',
  ]);
  const assetsUrl = userProfile?.versus?.character.assetsUrl;
  const charCode = userProfile?.versus?.character.code;

  const { containerRef, isLoaded, reInitDelayMs, dispose } = useSpineAnimation({
    assetsUrl: assetsUrl,
    charCode: charCode,
    accessoryName: selectedItem?.code,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: {
        x: 0,
        y: 440,
        width: 122,
        height: 750,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAccessory = (skinName: VersusAccessory | null) => {
    if (skinName === selectedItem) {
      return;
    }

    setSelectedItem(skinName);
  };

  const confirmSelect = async () => {
    setIsLoading(true);

    const code = selectedItem?.code ?? null;

    await versusCharacterService.applyAccessory(code);

    navigate('/play');
  };

  console.log(isLoaded);

  return (
    <div className={cn(s.root, className)}>
      <div
        className={cn(s.wormWrapper, { [s.visible]: isLoaded })}
        style={{ transitionDuration: `${reInitDelayMs}ms` }}
      >
        <div className={s.worm} ref={containerRef} />
      </div>

      <div className={s.wrapper}>
        <div className={s.scrollContainer}>
          <div className={s.itemsContainer}>
            <WormdrobeItem
              data={null}
              isDeselect
              onClick={selectAccessory}
              isActive={false}
              disabled={!isLoaded}
            />
            {userAccessories?.map((el) => (
              <WormdrobeItem
                key={el.code}
                data={el}
                isActive={selectedItem?.code === el.code}
                onClick={selectAccessory}
                disabled={!isLoaded}
              />
            ))}
          </div>
          <ShopForCard variant="wormdrobe" />
        </div>
      </div>
      <BottomButton
        label={!isLoading ? 'Select' : 'Loading..'}
        disabled={isLoading}
        onClick={confirmSelect}
        className={s.bottomButton}
      />
    </div>
  );
};
