import React, { Component, ReactNode } from 'react';

import s from './ErrorBoundary.module.scss';

interface Props {
  children: ReactNode | ReactNode[];
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    if (error instanceof Error && error.message.includes('401')) {
      return { hasError: false, error: null };
    }

    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by Error Boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.state.error) {
      return (
        <div className={s.root}>
          <h1 className={s.text}>Something went wrong!</h1>
          <p className={s.text}>
            <b>{this.state.error.message}</b>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
