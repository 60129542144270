export type StartParams = {
  ref?: string;
  questId?: string;
  address?: string;
  hash?: string;
};

export function parseStartParams<R>(): R | null {
  try {
    const searchParams = new URLSearchParams(
      window.location.search.replace('?', ''),
    );
    const startAppParam = searchParams.get('tgWebAppStartParam');

    if (!startAppParam) {
      throw new Error('No tgWebAppStartParam parameter in URL');
    }

    const paramsList = startAppParam.split('-');

    const result: { [key: string]: string } = {};

    for (const param of paramsList) {
      const [key, value] = param.split('_');

      if (key && value) {
        result[key] = value;
      }
    }

    return result as R;
  } catch (error) {
    console.warn('Error parsing link:', error);

    return null;
  }
}
