import { Injectable } from '@app/common/di';
import { MakeObservable, observable } from '@app/common/state';
import { ApiService } from '../ApiService';
import { VersusLeagueRewards } from '@app/types';
import { UserService } from '../UserService';
import { notifyError } from '@app/ui-kit/ToastNotifications';
import { userService } from '..';
import { preloadImage } from '@app/utils';

@Injectable()
@MakeObservable
export class VersusLeagueService {
  @observable
  public leagueRewards: VersusLeagueRewards[] = [];

  constructor(private readonly apiService: ApiService) {}

  async fetchLeagueRewards(): Promise<void> {
    try {
      this.leagueRewards = await this.apiService.get<VersusLeagueRewards[]>(
        '/versus/rank-rewards',
      );
    } catch (e) {
      console.error(e);
      notifyError(`Error: ${e}`);
    }
  }

  async claimRewards(rank: number): Promise<boolean> {
    try {
      this.leagueRewards = await this.apiService.post<VersusLeagueRewards[]>(
        '/versus/claim-rank-rewards',
        { rank },
      );
      await userService.fetchProfile();

      return true;
    } catch (e) {
      console.error(e);
      notifyError(`Error: ${e}`);

      return false;
    }
  }

  async preloadLeagues() {
    if (!this.leagueRewards || this.leagueRewards.length === 0) {
      await this.fetchLeagueRewards();
    }

    const preloadPromises = this.leagueRewards.map((league) =>
      preloadImage(league.leagueIcon),
    );

    await Promise.all(preloadPromises);
    console.log('All league icons preloaded');
  }
}
