import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';

import {
  ShopInvoice,
  ShopInvoiceType,
  type UserProfile,
  type VersusAccessory,
  type VersusShopItem,
  type VersusWormData,
  type VersusWssSkill,
} from '@app/types';
import { UserService } from '../UserService';
import { notifyError, notifySuccess } from '@app/ui-kit/ToastNotifications';
import { preloadImage } from '@app/utils';
import {
  SendTransactionRequest,
  TonConnectUI,
  Wallet,
  WalletInfoWithOpenMethod,
} from '@tonconnect/ui-react';
import { beginCell, Cell, fromNano } from '@ton/core';
import { analitycsService } from '..';
import { TonClient } from '@ton/ton';
import { ConfigService } from '../ConfigService';

@Injectable()
@MakeObservable
export class VersusCharacterService {
  @observable
  public userCharacters: VersusWormData[] | null = null;

  @observable
  public shopCharacters: VersusWormData[] | null = null;

  @observable
  public userAccessories: VersusAccessory[] | null = null;

  @observable
  public shopAccessories: VersusAccessory[] | null = null;

  @observable
  public shopItems: VersusShopItem[] | null = null;

  @observable
  public web3PurchaseInProgress = false;

  constructor(
    private readonly apiService: ApiService,
    private readonly configService: ConfigService,
    private readonly userService: UserService,
  ) {}

  async upgradeSkill(skillId: VersusWssSkill, onSuccess?: () => void) {
    try {
      const profile = await this.apiService.post<UserProfile>(
        '/versus/character/upgrade-skill',
        { skillId },
      );

      this.userService.updateProfile(profile);

      onSuccess?.();
    } catch (e) {
      console.error(e);
      notifyError(`Error: ${e}`);
    }
  }

  async getUserCharacters() {
    this.userCharacters = await this.apiService.get<VersusWormData[]>(
      '/versus/character/my',
    );
  }

  async getShopCharacters() {
    this.shopCharacters = await this.apiService.get<VersusWormData[]>(
      '/versus/character/available',
    );
  }

  async getCharacters() {
    await Promise.all([this.getUserCharacters(), this.getShopCharacters()]);
  }

  async purchaseCharacterWithTomato(code: string) {
    this.userCharacters = await this.apiService.post<VersusWormData[]>(
      '/versus/character/purchase',
      { code },
    );

    await this.getShopCharacters();
    await this.userService.fetchProfile();
  }

  async selectCharacter(code: string) {
    const profile = await this.apiService.post<UserProfile>(
      '/versus/character/activate',
      { code },
    );

    this.userService.updateProfile(profile);
  }

  async getUserAccessories() {
    this.userAccessories = await this.apiService.get<VersusAccessory[]>(
      '/versus/accessory/my',
    );
  }

  async getShopAccessories() {
    this.shopAccessories = await this.apiService.get<VersusAccessory[]>(
      '/versus/accessory/available',
    );
  }

  async getAccessories() {
    await Promise.all([this.getUserAccessories(), this.getShopAccessories()]);
  }

  async purchaseAccessoryWithTomato(code: string) {
    this.userCharacters = await this.apiService.post<VersusWormData[]>(
      '/versus/accessory/purchase',
      { code },
    );

    await this.getAccessories();
    await this.userService.fetchProfile();
  }

  async applyAccessory(code: string | null) {
    await this.apiService.post<VersusWormData[]>(
      '/versus/character/apply-accessory',
      { code },
    );

    await this.userService.fetchProfile();
  }

  async getShopItems() {
    this.shopItems =
      await this.apiService.get<VersusShopItem[]>('/versus/shop');
  }

  generateCharAssets(assetsUrl: string | undefined, code: string | undefined) {
    if (!assetsUrl || !code) {
      return null;
    }

    const charPath = `${assetsUrl}characters/${code}/`;

    const assets = {
      avatarLg: `${charPath}avatar-lg.png`,
      avatarMd: `${charPath}avatar-md.png`,
      avatarSm: `${charPath}avatar-sm.png`,
      logoLg: `${charPath}logo-lg.png`,
      logoMd: `${charPath}logo-md.png`,
      spineSkin: `${charPath}sprite.png`,
    };

    return assets;
  }

  private getCharCodes = async () => {
    const res = await this.apiService.get<string[]>('/versus/character/codes');

    return res;
  };

  async preloadCharacters() {
    if (!this.userCharacters || !this.shopCharacters) {
      await this.getCharacters();
    }

    if (!this.userAccessories || !this.shopAccessories) {
      await this.getAccessories();
    }

    const charsCodes = await this.getCharCodes();

    const assetsUrl = this.userCharacters?.[0]?.assetsUrl || '';

    if (assetsUrl) {
      await this.preloadCommonAssets(assetsUrl);
    }

    await Promise.all(
      charsCodes.map((charCode) =>
        this.preloadCharacterAssets(charCode, assetsUrl),
      ),
    );

    console.log(
      'All unique character assets preloaded and stored in IndexedDB',
    );
  }

  async preloadCommonAssets(assetsUrl: string) {
    const accessoriesPath = `${assetsUrl}accessories/`;

    const allAccessories = [
      ...(this.userAccessories || []),
      ...(this.shopAccessories || []),
    ];

    const accessories: VersusAccessory[] = Array.from(
      new Map(
        allAccessories.map((accessory) => [accessory.code, accessory]),
      ).values(),
    );

    const assetsToPreload = {
      atlas: `${assetsUrl}spine.atlas.txt`,
      skeleton: `${assetsUrl}spine.json`,
      commonHands1: `${assetsUrl}common/slap_2_worm_3.png`,
      commonHands2: `${assetsUrl}common/slap_2_worm_4.png`,
    };

    await Promise.all(
      Object.entries(assetsToPreload).map(async ([key, url]) => {
        const blob = await this.fetchAndConvertToBase64(url);

        await this.storeInIndexedDB(key, blob);
      }),
    );

    await Promise.all(
      accessories.map(async ({ code, location }) => {
        const spriteUrl = `${accessoriesPath}${code}/sprite.png`;
        const thumbnailUrl = this.generateAccessoryIcon(assetsUrl, code);

        const spriteBlob = await this.fetchAndConvertToBase64(spriteUrl);

        await this.storeInIndexedDB(code, spriteBlob, location);

        await preloadImage(thumbnailUrl);
      }),
    );

    console.log(
      'Common assets and accessories preloaded and stored in IndexedDB',
    );
  }

  generateAccessoryIcon(assetsUrl: string, code: string) {
    const accessoriesPath = `${assetsUrl}accessories/`;
    const thumbnailUrl = `${accessoriesPath}${code}/thumbnail-lg.png`;

    return thumbnailUrl;
  }

  generateItemImage(assetsUrl: string, code: string) {
    const url = `${assetsUrl}items/${code}.png`;

    return url;
  }

  async preloadCharacterAssets(charCode: string, assetsUrl: string) {
    const assets = this.generateCharAssets(assetsUrl, charCode);

    if (!assets) return;

    const { spineSkin, ...otherAssets } = assets;
    const skinKey = `${charCode}-skin`;

    const blob = await this.fetchAndConvertToBase64(spineSkin);

    await this.storeInIndexedDB(skinKey, blob);

    const preloadPromises = Object.values(otherAssets)
      .filter((url) => url.endsWith('.png'))
      .map((url) => preloadImage(url));

    await Promise.all(preloadPromises);
    console.log(`Preloaded images for character ${charCode}`);
  }

  async fetchAndConvertToBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  async openDatabase() {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open('VersusCharacterAssets', 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;

        db.createObjectStore('assets', { keyPath: 'key' });
      };
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async storeInIndexedDB(key: string, blob: string, itemLocation?: string) {
    const db = await this.openDatabase();

    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction('assets', 'readwrite');
      const store = transaction.objectStore('assets');

      store.put({ key, blob, ...(itemLocation && { itemLocation }) });

      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    });
  }

  async getItemFromIndexedDB(
    key: string,
  ): Promise<{ blob: string; itemLocation: string; key: string } | undefined> {
    const db = await this.openDatabase();
    const transaction = db.transaction('assets', 'readonly');
    const store = transaction.objectStore('assets');
    const request = store.get(key);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        const result = request.result as
          | { blob: string; itemLocation: string; key: string }
          | undefined;

        if (result) {
          resolve(result);
        } else {
          console.warn(`Asset with key "${key}" not found in IndexedDB.`);
          resolve(undefined);
        }
      };
      request.onerror = () => resolve(undefined);
    });
  }

  async loadCharacterAsset(charCode: string) {
    const assetBlob = await this.getItemFromIndexedDB(charCode);

    if (assetBlob) {
      console.log('Loaded asset:', assetBlob);
    } else {
      console.log('Asset not found in IndexedDB');
    }
  }

  async purchaseWithTon({
    wallet,
    tonConnectUI,
    tgUserId,
    shopItemCode,
    shopItemPrice,
    client,
    itemType,
    onTxSent,
    onItemReceived,
  }: PurchaseItemWithTon) {
    if (!tgUserId || !this.configService.serverConfig) {
      return;
    }

    if (!wallet?.account.address) {
      return;
    }

    this.web3PurchaseInProgress = true;

    const invoice = await this.createInvoice(shopItemCode, ShopInvoiceType.Ton);

    const body = beginCell()
      .storeUint(0, 32)
      .storeStringTail(invoice.id)
      .endCell();

    const tx: SendTransactionRequest = {
      validUntil: Date.now() + 5 * 60 * 1000,
      messages: [
        {
          address: this.configService.serverConfig.versusTreasuryAddress,
          amount: shopItemPrice,
          payload: body.toBoc().toString('base64'),
        },
      ],
    };

    const result = await tonConnectUI.sendTransaction(tx);

    const hash = Cell.fromBase64(result.boc).hash().toString('base64');

    // const txResult = await waitForTransaction(
    //   {
    //     address: wallet.account?.address ?? '',
    //     boc: result.boc,
    //   },
    //   client,
    // );

    if (!result) {
      return;
    }

    onTxSent();

    notifySuccess(
      'Transaction complete! Please hold on while we process your purchase — this may take up to 30 seconds.',
      { autoClose: 5000 },
    );

    console.log('Transaction completed', result);
    console.log('Start polling API for bought item');

    analitycsService.trackEvent({
      name: 'purchase',
      variables: {
        item: shopItemCode,
        amount: fromNano(shopItemPrice),
        currency: 'ton',
        transaction_hash: hash,
        invoice_id: invoice.id,
      },
    });

    const isItemAdded = await this.checkBoughtItemIsAdded(invoice.id, 20, 5000);

    if (isItemAdded) {
      await this.userService.fetchProfile();

      if (itemType === 'worm') {
        await this.getCharacters();
      }

      if (itemType === 'accessory') {
        await this.getAccessories();
      }

      onItemReceived?.();
    }

    this.web3PurchaseInProgress = false;
    console.log('shop item purchase completed');
  }

  async checkBoughtItemIsAdded(
    invoiceId: string,
    maxRetries = 15,
    pollInterval = 3000,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let attempts = 0;

      const checkApi = async () => {
        try {
          const invoice = await this.checkInvoiceStatus(invoiceId);

          if (invoice.isPaid === true) {
            clearInterval(timer);
            resolve(true);
          } else {
            console.log('API returned false, polling continues...');
          }

          attempts++;

          if (attempts >= maxRetries) {
            clearInterval(timer);
            console.log('Max retries limit reached cancelling polling');
          }
        } catch (error) {
          clearInterval(timer);
          reject(error);
        }
      };

      const timer = setInterval(checkApi, pollInterval);
    });
  }

  async purchaseItemWithStars({
    shopItemCode,
    shopItemPrice,
    itemType,
    onInvoicePaid,
    onItemReceived,
  }: PurchaseItemWithStars) {
    const invoice = await this.createInvoice(
      shopItemCode,
      ShopInvoiceType.Stars,
    );
    const { invoiceLink, id } = invoice;

    const invoiceStatus = await new Promise<
      'paid' | 'cancelled' | 'failed' | 'pending'
    >((resolve) => {
      Telegram.WebApp.openInvoice(invoiceLink, (status) => resolve(status));
    });

    if (invoiceStatus === 'paid') {
      onInvoicePaid();

      analitycsService.trackEvent({
        name: 'purchase',
        variables: {
          item: shopItemCode,
          amount: shopItemPrice,
          currency: 'stars',
          invoice_id: id,
        },
      });

      const isItemAdded = await this.checkBoughtItemIsAdded(
        invoice.id,
        10,
        3000,
      );

      if (isItemAdded) {
        await this.userService.fetchProfile();

        if (itemType === 'worm') {
          await this.getCharacters();
        }

        if (itemType === 'accessory') {
          await this.getAccessories();
        }

        onItemReceived?.();
      }
    }
  }

  async createInvoice(
    code: string,
    type: ShopInvoiceType,
  ): Promise<ShopInvoice> {
    const invoice = await this.apiService.post<ShopInvoice>(
      '/versus/shop/create-invoice',
      {
        code,
        type,
      },
    );

    return invoice;
  }

  async checkInvoiceStatus(id: string): Promise<ShopInvoice> {
    const invoice = await this.apiService.get<ShopInvoice>(`/invoice/${id}`);

    return invoice;
  }
}

interface PurchaseItemWithTon {
  wallet: Wallet | (Wallet & WalletInfoWithOpenMethod) | null;
  tonConnectUI: TonConnectUI;
  tgUserId: string | number | undefined;
  shopItemCode: string;
  shopItemPrice: string;
  client?: TonClient;
  itemType: 'worm' | 'accessory' | 'ticket' | 'cucumber';
  onTxSent: () => void;
  onItemReceived?: () => void;
}

interface PurchaseItemWithStars {
  shopItemCode: string;
  shopItemPrice: number;
  itemType: 'worm' | 'accessory' | 'ticket' | 'cucumber';
  onInvoicePaid: () => void;
  onItemReceived?: () => void;
}
