import { useEffect, useRef, useState } from 'react';
import { animate } from 'framer-motion';
import cn from 'classnames';

import { VersusLeagueRewards } from '@app/types';
import { RankWithProgress } from '@components/RankWithProgress';

import s from './LeagueChart.module.scss';
import { onboardingService } from '@app/services';

interface LeagueChartProps {
  userRank: number;
  selectedRankIndex: number;
  leagueData: VersusLeagueRewards[];
  transitionDurationSeconds: number;
  isOnboarding?: boolean;
  className?: string;
  zIndex?: number;
}

export const LeagueChart: React.FC<LeagueChartProps> = ({
  userRank,
  leagueData,
  selectedRankIndex,
  transitionDurationSeconds,
  isOnboarding,
  className,
  zIndex,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateScroll = () => {
    const target = document.querySelector(
      `[data-rank-index="${selectedRankIndex}"]`,
    ) as HTMLDivElement;

    if (target && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const badgeCenter =
        target.offsetLeft - containerWidth / 2 + target.offsetWidth / 2;

      const currentScroll = containerRef.current.scrollLeft;

      animate(currentScroll, badgeCenter, {
        type: 'tween',
        duration: !isMounted ? 0 : transitionDurationSeconds,
        onUpdate: (latest) => {
          if (containerRef.current) {
            containerRef.current.scrollLeft = latest;
          }
        },
      });
    }
  };

  useEffect(() => {
    calculateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, selectedRankIndex, isOnboarding]);

  useEffect(() => {
    const timer = setTimeout(() => setIsMounted(true), 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      calculateScroll();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRankIndex, isMounted]);

  return (
    <div className={cn(s.root, className)} style={{ zIndex }}>
      <div ref={containerRef} className={s.badgesContainer}>
        <div className={s.spacer} />
        {leagueData.map((league, index) => {
          const nextRank = leagueData[index + 1]?.rank ?? league.rank + 1;
          const isCurrentRank = league.rank <= userRank;

          const currentProgress = isCurrentRank
            ? Math.min(userRank - league.rank, nextRank - league.rank)
            : 0;
          const max = nextRank - league.rank;

          return (
            <div
              data-rank-index={index}
              key={league.rank}
              className={s.rankWrapper}
            >
              <RankWithProgress
                level={league.rank}
                currentProgress={currentProgress}
                min={0}
                max={max}
                showProgressValues={false}
                variant="leagueProgress"
                showBar={index !== leagueData.length - 1}
                rankImage={league.leagueIcon}
                transitionDurationsMs={
                  isOnboarding
                    ? onboardingService.rankIncreaseTime / 4
                    : undefined
                }
              />
              <div className={s.leagueName}>{league.leagueName}</div>
            </div>
          );
        })}
        <div className={s.spacer} />
      </div>
    </div>
  );
};
