import { useNavigate } from 'react-router-dom';

import { BackButton, Page, Wormdrobe } from '@app/components';

import s from './EquipPage.module.scss';

export const EquipPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton onClick={() => navigate('/play')} />
      <Wormdrobe />
    </Page>
  );
};
