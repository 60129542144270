import React from 'react';

import { BottomSheet, Button } from '@app/ui-kit';
import { useServiceState } from '@app/common/state';
import {
  onboardingService,
  versusAudioService,
  versusService,
} from '@app/services';
import underwear from '@media/versus/underwear.png';
import { isIOS } from '@app/utils';

import s from './RetreatConfirmBottomSheet.module.scss';

interface RetreatConfirmBottomSheetProps {
  isOpen: boolean;
  onClose: (result: boolean) => void;
}

export const RetreatConfirmBottomSheet: React.FC<
  RetreatConfirmBottomSheetProps
> = ({ isOpen, onClose }) => {
  const { isOnboarding } = useServiceState(onboardingService, ['isOnboarding']);

  const handleClose = () => {
    onClose(false);
  };

  const handleRetreat = () => {
    handleClose();

    if (isOnboarding) {
      onboardingService.retreat();
    } else {
      versusService.retreat();
    }
  };

  return (
    <BottomSheet
      bgColor="#26201C"
      open={isOpen}
      withClose={false}
      contentClassName={s.root}
    >
      <img src={underwear} alt="underwear" className={s.image} />
      <h3 className={s.title}>Sure?</h3>
      <div className={s.buttons} style={{ paddingBottom: isIOS() ? 24 : 0 }}>
        <Button onClick={handleRetreat}>Yes, Retreat now!</Button>
        <Button variant="secondary" onClick={handleClose}>
          No, Go back!
        </Button>
      </div>
    </BottomSheet>
  );
};
