import clsx from 'clsx';

import { BadgePoints } from '../BadgePoints';

import styles from './QuestItem.module.scss';
import { Default } from 'react-toastify/dist/utils';

interface QuestItemProps {
  variant?: 'defult' | 'tomato';
  level?: number;
  className?: string;
  icon: string;
  title: string;
  desc?: string;
  type?: string;
  scoreAmount?: number | string;
  isLocked?: boolean;
  lockedMessage?: string;
  isCompleted?: boolean;
  completeTimes?: number;
  maxCompleteTimes?: number;
  itemQuantity?: number;
  itemCompleted?: number;
  indicator?: boolean;
  onClick?: () => void;
}

export const QuestItem: React.FC<QuestItemProps> = ({
  variant = 'defult',
  className,
  isCompleted,
  icon,
  title,
  desc,
  lockedMessage,
  isLocked,
  scoreAmount,
  completeTimes,
  maxCompleteTimes,
  itemQuantity,
  itemCompleted,
  indicator,
  onClick,
}) => (
  <div className={clsx(styles.root, className)} onClick={onClick}>
    {indicator && <span className={styles.indicator}></span>}
    <div className={styles.content}>
      <div className={styles.wrap}>
        {!isLocked && !isCompleted && !!scoreAmount && variant === 'defult' ? (
          <BadgePoints
            variant="default"
            size="small"
            value={scoreAmount}
            className={styles.badge}
          />
        ) : null}
        {!isLocked && !isCompleted && !!scoreAmount && variant === 'tomato' ? (
          <BadgePoints
            variant="default"
            size="small"
            value={scoreAmount}
            className={styles.badge}
          />
        ) : null}

        {!isLocked && isCompleted && !!scoreAmount ? (
          <BadgePoints
            variant="completed"
            size="small"
            value="Completed"
            className={styles.badge}
          />
        ) : null}

        {isLocked && !!scoreAmount ? (
          <BadgePoints
            variant="inactive"
            size="small"
            value={scoreAmount}
            className={styles.badge}
          />
        ) : null}
        {!!itemQuantity && (
          <span className={styles.completed}>
            <span>{itemCompleted}</span> <span>/</span>
            <span>{itemQuantity}</span>
          </span>
        )}
      </div>

      <h4 className={styles.title}>{title}</h4>
      <p className={styles.desc}>{isLocked ? lockedMessage : desc}</p>
      {maxCompleteTimes && (
        <p className={styles.limited}>
          <span className={styles.limitedDesc}>Limited:</span>
          <span className={styles.available}>
            <span>{completeTimes?.toLocaleString('en-US')}</span> <span>/</span>
            <span>{maxCompleteTimes.toLocaleString('en-US')}</span>
          </span>
        </p>
      )}
    </div>
    <div className={styles.iconWrap}>
      <img src={icon} className={styles.icon} alt="Quest Icon" />
    </div>
  </div>
);
