import { OnboardingData } from '@app/types/onboarding';

export const onboardingData: OnboardingData[] = [
  {
    id: 'hereHp',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '115px',
      left: '10px',
    },
    arrow: {
      position: 'absolute',
      rotate: '-90deg',
      left: 0,
      top: '-48px',
    },
    content: (
      <p>
        Worm <span>HP</span> Here
      </p>
    ),
    zIndexPlayerBars: 3,
    zIndexWorm: 1,
    zIndexWheel: 1,
  },
  {
    id: 'opponentHp',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '115px',
      right: '10px',
    },
    arrow: {
      position: 'absolute',
      rotate: '-90deg',
      right: 0,
      top: '-48px',
    },
    content: (
      <p>
        Opponent's Worm <span>HP</span>
      </p>
    ),
    zIndexPlayerBars: 3,
    zIndexWorm: 1,
    zIndexWheel: 1,
  },
  {
    id: 'reduceHp',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '115px',
      right: '10px',
      width: '257px',
    },
    arrow: {
      position: 'absolute',
      rotate: '-90deg',
      right: 0,
      top: '-48px',
    },
    content: (
      <p>
        <span>Reduce</span> Opponent's HP to Zero
      </p>
    ),
    zIndexPlayerBars: 3,
    zIndexWorm: 1,
    zIndexWheel: 1,
  },
  {
    id: 'startRound',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    arrow: {
      position: 'absolute',
      rotate: '90deg',
      left: '50%',
      transform: 'translateY(50%)',
      bottom: '-48px',
    },
    content: <p>Let's Start!</p>,
    zIndexPlayerBars: 3,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },
  {
    id: 'slapPower',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    arrow: {
      position: 'absolute',
      rotate: '90deg',
      left: '50%',
      transform: 'translateY(50%)',
      bottom: '-48px',
    },
    content: (
      <p>
        Your <span>Slap Power</span>
      </p>
    ),
    zIndexPlayerBars: 1,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },
  {
    id: 'powerLevels',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '316px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        <span style={{ color: '#ABED5E' }}>Green</span>: Maximum Power{' '}
        <span>Yellow</span>: Half Power{' '}
        <span style={{ color: '#C9B69C' }}>Gray</span>: Missed Slap
      </p>
    ),
    zIndexPlayerBars: 1,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },
  {
    id: 'tapToGreen',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '296px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        Tap When Arrow Hits <span style={{ color: '#ABED5E' }}>Green</span>
      </p>
    ),
    showCircles: true,
    zIndexPlayerBars: 1,
    zIndexWorm: 3,
    zIndexWheel: 3,
  },
  {
    id: 'missed',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '296px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        Oops! Missed! Try to catch the{' '}
        <span style={{ color: '#ABED5E' }}>Green</span>
      </p>
    ),
    showCircles: true,
    zIndexPlayerBars: 1,
    zIndexWorm: 3,
    zIndexWheel: 3,
  },
  {
    id: 'missed-2',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '296px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        Oops! Missed! Try to catch the{' '}
        <span style={{ color: '#ABED5E' }}>Green</span>
      </p>
    ),
    showCircles: false,
    zIndexPlayerBars: 1,
    zIndexWorm: 3,
    zIndexWheel: 3,
  },
  {
    id: 'showHitForce',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '222px',
    },
    arrow: {
      display: 'none',
    },
    content: <p>Crushing Slap!</p>,
    zIndexPlayerBars: 1,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },
  {
    id: 'waitOpponent',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '300px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '316px',
    },
    arrow: {
      position: 'absolute',
      rotate: '125deg',
      left: '8%',
      bottom: '-85%',
    },
    content: (
      <p>
        You have <span>20 Seconds</span> to Catch Arrow. Waiting for Opponent...
      </p>
    ),
    zIndexPlayerBars: 1,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },

  {
    id: 'roundWin',
    container: { zIndex: 10 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '316px',
    },
    arrow: {
      display: 'none',
    },
    content: <p>Great Job! May Take Several Rounds to Win</p>,
    zIndexPlayerBars: 1,
    zIndexWorm: 2,
    zIndexWheel: 2,
  },
  {
    id: 'changePattern',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '270px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '316px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        <span style={{ color: '#ABED5E' }}>New</span> <span>Pattern</span>{' '}
        <span style={{ color: '#C9B69C' }}>Each</span> Time!
      </p>
    ),
    zIndexPlayerBars: 1,
    zIndexWorm: 1,
    zIndexWheel: 3,
  },
  {
    id: 'winToLvlUp',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '113px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '258px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        Win to Level Up in
        <br />
        <span style={{ color: 'var(--text-accent-orange)' }}>rank</span> and
        <span style={{ color: 'var(--text-accent-orange)' }}> League</span>!
      </p>
    ),
    zIndexLevel: 3,
  },
  {
    id: 'earnTomatoes',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '113px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '258px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        <span style={{ color: 'var(--text-accent-orange)' }}>
          Earn tomatoes
        </span>
        <br />
        Each Battle!
      </p>
    ),
    zIndexBalance: 3,
  },
  {
    id: 'cucumbersLevelUp',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '132px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '258px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        <span style={{ color: 'var(--text-accent-orange)' }}>Cucumbers</span>
        <br />
        Level Up Skills!
      </p>
    ),
    zIndexTopUps: 3,
    zIndexBalance: 4,
  },
  {
    id: 'increaseSlaps',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      bottom: '132px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '258px',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        <span style={{ color: 'var(--text-accent-orange)' }}>Increase</span>{' '}
        Slap's
        <br />
        Max Power!
      </p>
    ),
    zIndexSkill: 3,
  },
  {
    id: 'collectLeagueReward',
    container: { zIndex: 2 },
    msg: {
      position: 'absolute',
      top: '100px',
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: '360px',
      width: '100%',
    },
    arrow: {
      display: 'none',
    },
    content: (
      <p>
        You’re in Microbe League
        <br />
        <span style={{ color: 'var(--text-accent-orange)' }}>
          Collect Rewards!
        </span>
      </p>
    ),
    zIndexLeague: 3,
  },
];
