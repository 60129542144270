import { imagesToPreload } from '@app/constants/imagesToPreload';
import {
  authService,
  randomSlapSoundService,
  versusAudioService,
  versusCharacterService,
  versusLeagueService,
} from '@app/services';
import { preloadImage } from '@app/utils';
import { useEffect, useState } from 'react';

export const usePreloadResources = (isAuthLoading: boolean) => {
  const [resourceStatus, setResourceStatus] = useState({
    images: false,
    sounds: false,
    leagues: false,
    characters: false,
  });

  const [allResourcesLoaded, setAllResourcesLoaded] = useState(false);

  const totalResources = Object.keys(resourceStatus).length;
  const loadedResources = Object.values(resourceStatus).filter(Boolean).length;

  const progress = Math.floor((loadedResources / totalResources) * 100);

  useEffect(() => {
    if (!authService.isAuth() || isAuthLoading) {
      return;
    }

    const updateResourceStatus = (key: keyof typeof resourceStatus) => {
      setResourceStatus((prev) => ({ ...prev, [key]: true }));
    };

    const preloadImages = Promise.all(
      imagesToPreload.map((url) => preloadImage(url)),
    ).then(() => updateResourceStatus('images'));

    const preloadAudio = versusAudioService
      .preloadAudio()
      .then(() => updateResourceStatus('sounds'));

    const preloadLeagues = versusLeagueService
      .preloadLeagues()
      .then(() => updateResourceStatus('leagues'));

    const preloadCharacters = versusCharacterService
      .preloadCharacters()
      .then(() => updateResourceStatus('characters'));

    Promise.all([
      preloadImages,
      preloadAudio,
      preloadLeagues,
      preloadCharacters,
    ])
      .then(() => {
        setTimeout(() => {
          setAllResourcesLoaded(true);
        }, 300);
      })
      .catch((e) => console.error('Error preloading resources ----', e));
  }, [isAuthLoading]);

  return { allResourcesLoaded, progress, resourceStatus };
};
