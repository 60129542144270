import React from 'react';
import clsx from 'clsx';

import urlAlertIconInfo from '@media/tomato_coin.png';
import urlAlertIconSuccess from '@icons/check_mark.png';
import urlAlertIconError from '@icons/error.png';
import { ReactComponent as UrlAlertIconWarning } from '@icons/warning.svg';

import s from './Alert.module.scss';

interface AlertProps {
  className?: string;
  alertMsg: string;
  variant?: 'info' | 'success' | 'error' | 'warning';
}

export const Alert: React.FC<AlertProps> = ({
  className,
  alertMsg,
  variant = 'info',
}) => {
  return (
    <div className={clsx(s.root, className, s[variant])}>
      {variant === 'info' && (
        <img
          className={s.icon}
          src={urlAlertIconInfo}
          alt="alert icon"
          width={23}
          height={19}
        />
      )}

      {variant === 'success' && (
        <img
          className={s.icon}
          src={urlAlertIconSuccess}
          alt="alert icon"
          width={20}
          height={20}
        />
      )}
      {variant === 'error' && (
        <img
          className={s.icon}
          src={urlAlertIconError}
          alt="alert icon"
          width={17}
          height={20}
        />
      )}
      {variant === 'warning' && (
        <UrlAlertIconWarning
          className={s.icon}
          width={20}
          height={20}
          color="var(--text-on-actions-dark)"
        />
      )}

      <p className={s.msg}>{alertMsg}</p>
    </div>
  );
};
