import cn from 'classnames';

import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg';
import { Button } from '@app/ui-kit';
import { VersusLeagueRewards } from '@app/types';
import { numberWithCommas } from '@app/utils';

import s from './RankRewardCard.module.scss';
import { ImageLoader } from '../ImageLoader';

interface RankRewardCardProps {
  rewardsData: VersusLeagueRewards;
  userRank: number;
  currentIndex: number;
  prevDisabled: boolean;
  nextDisabled: boolean;
  className?: string;
  onClaim: () => void;
  onPrev: (index: number) => void;
  onNext: (index: number) => void;
}

export const RankRewardCard: React.FC<RankRewardCardProps> = ({
  rewardsData,
  userRank,
  currentIndex,
  prevDisabled,
  nextDisabled,
  onClaim,
  onPrev,
  onNext,
  className,
}) => {
  const isUnlocked = rewardsData.isClaimed || userRank >= rewardsData.rank;

  return (
    <div className={cn(s.root, className)}>
      <button
        disabled={prevDisabled}
        onClick={() => onPrev(currentIndex)}
        className={cn(s.navButton, s.prev)}
      >
        <ArrowRightIcon className={s.arrow} />
      </button>
      <button
        disabled={nextDisabled}
        onClick={() => onNext(currentIndex)}
        className={cn(s.navButton, s.next)}
      >
        <ArrowRightIcon className={s.arrow} />
      </button>

      <div className={s.rewards}>
        {rewardsData.rewards.map((reward, i) => (
          <div
            className={s.rewardWrapper}
            key={reward.image + rewardsData.leagueName}
          >
            <ImageLoader
              src={reward.image}
              className={s.rewardImage}
              alt="reward"
            />
            {reward.amount && (
              <div className={s.rewardText}>
                {numberWithCommas(reward.amount)}
              </div>
            )}
          </div>
        ))}
      </div>
      {isUnlocked && (
        <Button
          onClick={onClaim}
          className={s.claimBtn}
          disabled={rewardsData.isClaimed}
        >
          {rewardsData.isClaimed ? 'Claimed!' : 'Claim'}
        </Button>
      )}
      {!isUnlocked && (
        <div className={s.unlockText}>
          Win more slap battles to join the {rewardsData.leagueName} League and
          Grab you Rewards
        </div>
      )}
    </div>
  );
};
