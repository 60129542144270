import React from 'react';
import clsx from 'clsx';

import noFriendsImgUrl from '@media/no-friends.webp';
import nothingHereImgUrl from '@media/nothing-here.webp';

import s from './EmptyListState.module.scss';

interface EmptyListStateProps {
  className?: string;
  variant?: 'no-friends' | 'nothing-here' | 'custom';
  borderRadius?: 'none' | 'rounded' | 'bottom';
  title?: string;
  desc?: string;
  img?: string;
  imgWidth?: number | string;
  imgHeight?: number | string;
}

export const EmptyListState: React.FC<EmptyListStateProps> = ({
  className,
  variant = 'no-friends',
  borderRadius = 'none',
  title,
  desc,
  img,
  imgWidth,
  imgHeight,
}) => {
  return (
    <div className={clsx(s.root, className, s[variant], s[borderRadius])}>
      {title && variant !== 'nothing-here' && variant !== 'no-friends' && (
        <h4 className={s.title}>{title}</h4>
      )}

      {desc && variant !== 'nothing-here' && variant !== 'no-friends' && (
        <p className={s.desc}>{desc}</p>
      )}
      {variant !== 'custom' ? (
        <img
          className={s.img}
          src={variant === 'no-friends' ? noFriendsImgUrl : nothingHereImgUrl}
          alt="empty list"
          width={302}
          height={229}
        />
      ) : (
        <img
          className={s.img}
          src={img}
          alt="empty list"
          width={imgWidth}
          height={imgHeight}
        />
      )}
    </div>
  );
};
