import React from 'react';
import clsx from 'clsx';

import defaultIcon from '@media/tomato_coin.png';

import s from './RewardItem.module.scss';

interface RewardItemProps {
  className?: string;
  value: string | number;
  icon?: string;
  title?: string;
  btnTitle?: string;
  onClick?: () => void;
}

export const RewardItem: React.FC<RewardItemProps> = ({
  className,
  value,
  icon = defaultIcon,
  title,
  btnTitle,
  onClick,
}) => {
  const classNames = clsx(s.root, className);
  const formattedNumber = value.toLocaleString('en-US');

  return (
    <div className={classNames}>
      <p className={s.title}>{!title ? 'Your reward' : title}</p>
      <div className={s.wrap}>
        <img className={s.img} src={icon} alt="point" />
        <span className={s.value}>{formattedNumber}</span>
      </div>
      {btnTitle && (
        <button className={s.link} onClick={onClick}>
          <span>{btnTitle}</span>
        </button>
      )}
    </div>
  );
};
