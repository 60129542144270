import cn from 'classnames';

import { ImageLoader, VersusPriceTag } from '@components';
import { VersusPriceType, VersusShopItem } from '@app/types';
import { numberWithCommas } from '@app/utils';
import { versusCharacterService } from '@app/services';

import s from './ShopItemCard.module.scss';

interface ShopItemCardCardProps {
  data: VersusShopItem;
  className?: string;
  onClick: () => void;
}

export const ShopItemCard: React.FC<ShopItemCardCardProps> = ({
  data,
  className,
  onClick,
}) => {
  const {
    amount,
    price,
    priceFull,
    priceTon,
    priceType,
    priceStars,
    assetsUrl,
    code,
  } = data;

  const image = versusCharacterService.generateItemImage(assetsUrl, code);

  return (
    <button className={cn(s.root, className)} onClick={onClick}>
      <ImageLoader src={image} alt={'cucumber'} className={s.image} />
      <div className={s.amount}>{numberWithCommas(amount)}</div>
      <VersusPriceTag
        price={price || 0}
        priceTon={priceTon}
        priceType={priceType}
        priceFull={priceFull}
      />

      {priceStars && (
        <VersusPriceTag
          price={priceStars}
          priceType={VersusPriceType.Stars}
          className={s.starsPrice}
        />
      )}
    </button>
  );
};
