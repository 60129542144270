import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import {
  MatchMakingStatus,
  MatchMakingWorm,
  Page,
  VersusPlayerBars,
} from '@components';
import { BackButton } from '@components';
import foregroundImg from '@media/matchmaking-foreground.png';
import { useServiceState } from '@app/common/state';
import { versusService } from '@app/services';

import s from './MatchMakingPage.module.scss';

export const MatchMakingPage = () => {
  const navigate = useNavigate();

  const { player, opponent, isConnected } = useServiceState(versusService, [
    'player',
    'opponent',
    'isConnected',
  ]);

  useEffect(() => {
    if (!isConnected) {
      navigate('/play');
    }
  }, [isConnected, navigate]);

  const handleCancel = async () => {
    versusService.cancelMatchMaking();
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={handleCancel} />
      <VersusPlayerBars
        player={player}
        opponent={opponent}
        className={s.playersBar}
      />
      <div className={s.wrapper}>
        <MatchMakingWorm
          assetsUrl={player?.character.assetsUrl}
          charCode={player?.character.code}
          accessoryName={player?.character.accessories?.[0]?.code}
          animationName={opponent ? 'matchmaking-emotion1' : 'matchmaking-idle'}
          className={s.player}
        />
      </div>
      {opponent && (
        <div className={s.wrapper}>
          <MatchMakingWorm
            assetsUrl={opponent?.character.assetsUrl}
            charCode={opponent?.character.code}
            accessoryName={opponent?.character.accessories?.[0]?.code}
            animationName={
              opponent ? 'matchmaking-emotion2' : 'matchmaking-idle'
            }
            className={s.opponent}
          />
        </div>
      )}
      <motion.img
        initial={{ scale: 1.5 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'tween',
          duration: 0.5,
        }}
        src={foregroundImg}
        width={'100%'}
        height={'100%'}
        className={s.image}
        alt="ground"
      />
      <MatchMakingStatus
        mode={!opponent ? 'looking' : 'found'}
        onCancel={handleCancel}
        className={s.status}
      />
    </Page>
  );
};
