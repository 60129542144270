import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import {
  BackButton,
  BottomButton,
  CucumberBalance,
  OnboardingAlert,
  Page,
  VersusRewardsPopUps,
  VersusSkillsList,
} from '@app/components';
import { onboardingService, userService, versusService } from '@app/services';
import { useServiceState } from '@app/common/state';
import { useSpineAnimation } from '@app/hooks';

import s from './SkillsPage.module.scss';

export const SkillsPage: React.FC = () => {
  const navigate = useNavigate();
  const { mode } = useParams<{ mode: 'default' | 'battle-result' }>();
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { battleResultInfo } = useServiceState(versusService, [
    'battleResultInfo',
  ]);

  const { onboardingMessage } = useServiceState(onboardingService, [
    'onboardingMessage',
  ]);

  const isBattleResultMode = mode === 'battle-result';

  const initialBalance = isBattleResultMode
    ? battleResultInfo?.versusScore ?? 0
    : userProfile?.versus?.score ?? 0;

  const [newBalance, setNewBalance] = useState(initialBalance);

  const isDefault = mode === 'default';

  const skillsData = userProfile?.versus?.character.skills || [];

  const { containerRef, isLoaded, dispose } = useSpineAnimation({
    assetsUrl: userProfile?.versus?.character.assetsUrl,
    charCode: userProfile?.versus?.character.code,
    accessoryName: userProfile?.versus?.character.accessories?.[0]?.code,
    config: {
      preserveDrawingBuffer: false,
      animation: 'idle',
      alpha: true,
      viewport: {
        x: 0,
        y: 430,
        width: 122,
        height: 750,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewBalance(initialBalance);
  }, [initialBalance]);

  useEffect(() => {
    if (isBattleResultMode && !battleResultInfo?.rewards) {
      navigate('/play');
    }
  }, [battleResultInfo?.rewards, isBattleResultMode, navigate]);

  const updateBalance = useCallback(
    (index: number) => {
      if (battleResultInfo?.rewards) {
        setNewBalance((prev) => prev + battleResultInfo.rewards[index].points);
      }
    },
    [battleResultInfo],
  );

  const handleSkillUpdate = useCallback((cost: number) => {
    setNewBalance((prev) => Math.max(0, prev - cost));
  }, []);

  const handleNext = async () => {
    if (battleResultInfo?.shouldNavigateToLeague) {
      navigate('/league-progression');
    } else {
      navigate('/play');
    }
  };

  return (
    <>
      <Page isHaveBottomNav={false} className={s.root}>
        <BackButton onClick={() => navigate('/play')} />
        <VersusRewardsPopUps
          data={isBattleResultMode ? battleResultInfo?.rewards : null}
          onStartAnimation={(i) => updateBalance(i)}
          zIndex={onboardingMessage?.zIndexTopUps}
        />
        <div
          className={cn(s.worm, { [s.loaded]: isLoaded })}
          ref={containerRef}
        />
        <CucumberBalance
          balance={newBalance}
          animate={newBalance !== initialBalance}
          animateDuration={isDefault ? 0.5 : 1.5}
          zIndex={onboardingMessage?.zIndexBalance}
          className={s.balance}
        />
        <VersusSkillsList
          balance={newBalance}
          items={skillsData}
          zIndex={onboardingMessage?.zIndexSkill}
          className={s.skillsList}
          onSkillUpdate={handleSkillUpdate}
        />
        {isBattleResultMode && (
          <BottomButton label="Next" onClick={handleNext} />
        )}
        <OnboardingAlert
          onboardingMessage={onboardingMessage}
          onClick={() => onboardingService.nextOnboardingStep()}
        />
      </Page>
    </>
  );
};
