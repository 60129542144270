import { m, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import rank1 from '@media/league/rank-1.png';

import s from './RankBadge.module.scss';

interface RankBadgeProps {
  level: number | null;
  fontSize?: number;
  transitionDurationSeconds?: number;
  rankImage: string | undefined;
  className?: string;
}

export const RankBadge: React.FC<RankBadgeProps> = ({
  level,
  transitionDurationSeconds = 0.5,
  rankImage,
  className,
}) => {
  const getRankImage = () => {
    if (rankImage && rankImage !== '') {
      return rankImage;
    }

    return rank1;
  };

  const renderImage = getRankImage();

  return (
    <div className={cn(s.root, className)}>
      <AnimatePresence mode="popLayout" initial={false}>
        <m.img
          key={renderImage}
          src={renderImage}
          alt={`Rank for level ${level}`}
          className={s.rankImage}
          initial={{ opacity: 0, scale: 1.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            duration: transitionDurationSeconds,
            type: 'spring',
            stiffness: 100,
            bounce: 15,
          }}
        />
      </AnimatePresence>
      {level !== null && (
        <AnimatePresence mode="popLayout" initial={false}>
          <m.div
            key={level}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'tween', duration: transitionDurationSeconds }}
            className={s.level}
          >
            {level}
          </m.div>
        </AnimatePresence>
      )}
    </div>
  );
};
