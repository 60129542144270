import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import iconReaction from '@app/assets/media/skills/reaction.png';
import { NavItem } from '@app/components/NavBars/NavItem';
import { Button } from '@app/ui-kit';
import { RankWithProgress } from '@components';

import s from './SlapVersusMenu.module.scss';
import {
  onboardingService,
  userService,
  versusAudioService,
  versusService,
} from '@app/services';
import { useServiceState } from '@app/common/state';

interface SlapVersusMenuProps {
  className?: string;
}

export const SlapVersusMenu: React.FC<SlapVersusMenuProps> = ({
  className,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const { rank, progress, progressMax, leagueIcon } =
    userProfile?.versus?.rating || {};
  const isOnboardingCompleted =
    userProfile?.versus?.isOnboardingCompleted ?? false;
  const { playPressed } = useServiceState(versusService, ['playPressed']);

  const navigate = useNavigate();

  const handlePlay = () => {
    if (!isOnboardingCompleted) {
      onboardingService.startOnboarding();
    } else {
      versusService.connectToServer(() => {
        versusService.initGame();
      });
    }
  };

  return (
    <div className={cn(s.root, className)}>
      <div className={s.background} />
      <NavItem
        label={'Skills'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.img}
              src={iconReaction}
              width={40}
              height={40}
              alt="skills"
            />
          </div>
        }
        onClick={() => {
          versusAudioService.playSoundEffect('buttonClick');
          navigate('/skills/default');
        }}
      />
      <Button
        onClick={handlePlay}
        disabled={playPressed}
        className={s.button}
        size="large"
      >
        Play
      </Button>
      <RankWithProgress
        variant="menu"
        level={rank}
        currentProgress={progress}
        max={progressMax}
        rankImage={leagueIcon}
        showProgressValues
        onClick={() => {
          versusAudioService.playSoundEffect('buttonClick');
          navigate('/league-progression');
        }}
        className={s.rank}
      />
    </div>
  );
};
