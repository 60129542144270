import React from 'react';
import clsx from 'clsx';

import { Wheel } from './components/Wheel';
import { Arrow } from './components/Arrow';
import { Timer } from './components';

import s from './VersusBattleWheel.module.scss';

interface VersusBattleWheelProps {
  className?: string;
  zIndex?: number;
}

export const VersusBattleWheel: React.FC<VersusBattleWheelProps> = ({
  zIndex,
  className,
}) => {
  return (
    <div className={clsx(s.root, className)} style={{ zIndex }}>
      <div className={s.container}>
        <Timer className={s.timer} />
        <Wheel className={s.wheel} />
        <Arrow className={s.arrow} />
      </div>
    </div>
  );
};
