import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

import { VersusPlayer } from '@app/types';
import { PlayerInfo } from './PlayerInfo';
import tomatoIcon from '@media/tomato_coin.png';
import { useServiceState } from '@app/common/state';
import { versusService } from '@app/services';

import s from './VersusPlayerBars.module.scss';
import { kNumberFormatter } from '@app/utils';

interface VersusPlayerBarsProps {
  player: VersusPlayer | null;
  opponent: VersusPlayer | null;
  score?: number | null;
  zIndex?: number;
  className?: string;
  transitionDelayMs?: number;
}

export const VersusPlayerBars: React.FC<VersusPlayerBarsProps> = ({
  player,
  opponent,
  score,
  zIndex,
  transitionDelayMs,
  className,
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isOpponentAvailable = opponent != null && opponent !== undefined;

  useEffect(() => {
    setShouldAnimate(!opponent?.skills);
  }, [opponent?.skills]);

  return (
    <div className={cn(s.root, className)} style={{ zIndex }}>
      {player && (
        <PlayerInfo
          player={player}
          transitionDelayMs={transitionDelayMs}
          mirror={false}
          className={s.playerInfo}
        />
      )}
      <AnimatePresence initial>
        {score !== null && score !== undefined && (
          <motion.div
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            className={s.col}
            transition={{ type: 'spring', duration: 0.5, delay: 1 }}
          >
            <img className={s.balanceIcon} src={tomatoIcon} alt="tomato" />
            <div className={s.balanceValue}>{kNumberFormatter(score)}</div>
          </motion.div>
        )}
      </AnimatePresence>

      {isOpponentAvailable && shouldAnimate ? (
        <motion.div
          className={s.playerInfo}
          initial={{ y: -100 }}
          animate={{ y: 0 }}
        >
          <PlayerInfo
            player={opponent}
            transitionDelayMs={transitionDelayMs}
            mirror
          />
        </motion.div>
      ) : (
        isOpponentAvailable && (
          <PlayerInfo
            player={opponent}
            transitionDelayMs={transitionDelayMs}
            mirror
            className={s.playerInfo}
          />
        )
      )}
    </div>
  );
};
