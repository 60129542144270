import cn from 'classnames';

import tickIcon from '@media/tick-icon.png';

import { CircularSkillProgress, VersusPriceTag } from '@components';
import { VersusPriceType, VersusWormData } from '@app/types';
import { versusCharacterService } from '@app/services';

import s from './WormCard.module.scss';

interface WormCardProps {
  wormInfo: VersusWormData;
  isSelected?: boolean;
  className?: string;
  onClick: (code: string) => void;
}

export const WormCard: React.FC<WormCardProps> = ({
  wormInfo,
  isSelected,
  className,
  onClick,
}) => {
  const {
    code,
    assetsUrl,
    price,
    priceStars,
    priceFull,
    priceType,
    skills,
    startSkills,
    color,
    name,
    isOwned,
  } = wormInfo;
  const assets = versusCharacterService.generateCharAssets(assetsUrl, code);

  const handleClick = (code: string) => {
    onClick(code);
  };

  return (
    <button
      className={cn(
        s.root,
        { [s.selected]: isSelected, [s.disabled]: isOwned },
        className,
      )}
      onClick={() => handleClick(code)}
    >
      {isOwned && <div className={s.ownedTag}>Owned</div>}
      <div className={s.wormColor} style={{ backgroundColor: color }}>
        <div className={s.avatarWrapper}>
          <img
            src={assets?.avatarLg || ''}
            alt={name}
            className={s.avatarImage}
          />
        </div>
      </div>
      <img
        src={assets?.logoLg || ''}
        alt="worm"
        className={s.avatarNameImage}
      />
      <div className={s.skills}>
        {(startSkills ?? skills)?.map((skill) => (
          <CircularSkillProgress
            skill={skill.id}
            currentSkillLevel={skill.level}
            generalMaxLevel={skill.generalMaxLevel}
            currentMaxLevel={skill.maxLevel}
          />
        ))}
      </div>
      {price !== undefined && priceType && (
        <VersusPriceTag
          price={price}
          priceFull={priceFull}
          priceType={priceType}
          className={s.price}
        />
      )}
      {priceStars && (
        <VersusPriceTag
          price={priceStars}
          priceType={VersusPriceType.Stars}
          className={s.starsPrice}
        />
      )}
    </button>
  );
};
