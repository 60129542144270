import cn from 'classnames';

import { Button } from '@app/ui-kit';

import s from './ButtonPurchaseStars.module.scss';
import { VERSUS_ITEM_PRICE } from '@app/constants/versus';
import { VersusPriceType } from '@app/types';

interface ButtonPurchaseStarsProps {
  stars: number;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

export const ButtonPurchaseStars: React.FC<ButtonPurchaseStarsProps> = ({
  stars,
  disabled,
  className,
  onClick,
}) => {
  return (
    <Button
      variant="tertiary"
      bgColor="var(--action-secondary-base-default)"
      size="medium"
      disabled={disabled}
      className={cn(s.root, className)}
      onClick={onClick}
    >
      <div className={s.content}>
        <div>or Purchase with Stars</div>

        <div className={s.stars}>
          <img
            src={VERSUS_ITEM_PRICE[VersusPriceType.Stars].icon}
            alt="stars"
            className={s.icon}
          />
          <div>{stars}</div>
        </div>
      </div>
    </Button>
  );
};
