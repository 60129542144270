import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from '@app/ui-kit';
import { ReactComponent as ChevronRight } from '@icons/chevron-right.svg';
import slapImage from '@media/slap-2-card.png';
import heroImg from '@media/home-hero.png';

import s from './HomeWidgetSlapBeta.module.scss';

interface HomeWidgetSlapBetaProps {
  className?: string;
}

export const HomeWidgetSlapBeta: React.FC<HomeWidgetSlapBetaProps> = ({
  className,
}) => {
  const navigate = useNavigate();

  return (
    <div className={cn(s.root, className)}>
      <img src={heroImg} className={s.heroImg} alt="worm heroes" />
      <img src={slapImage} className={s.image} alt="slap-2.0" />
      <h5 className={s.title}>Get into High League</h5>
      <p className={s.description}>
        Progress in Slap Versus before the Tournaments launch
      </p>
      <Button
        bgColor="var(--action-primary-default)"
        size="medium"
        gap="2px"
        rightIcon={
          <ChevronRight
            width={16}
            height={16}
            color="var(--text-on-actions-dark)"
          />
        }
        variant="tertiary"
        className={s.button}
        onClick={() => navigate('/play')}
      >
        Play Now
      </Button>
    </div>
  );
};
