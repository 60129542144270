import React from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import { useServiceState } from '@app/common/state';
import { versusService } from '@app/services';

import s from './FightHpAnimation.module.scss';
import { HitForce } from '@app/types';

interface FightHpAnimationProps {
  className?: string;
}

export const FightHpAnimation: React.FC<FightHpAnimationProps> = ({
  className,
}) => {
  const {
    fightState,
    playerHitForce,
    opponentHitForce,
    opponentDamage,
    playerDamage,
  } = useServiceState(versusService, [
    'fightState',
    'playerHitForce',
    'opponentHitForce',
    'opponentDamage',
    'playerDamage',
  ]);

  if (!fightState) {
    return null;
  }

  const isPlayerTurn = fightState === 'playerTurn';
  const hitForce = isPlayerTurn ? playerHitForce : opponentHitForce;

  const hitForceColor = (() => {
    switch (hitForce) {
      case HitForce.miss:
        return s.hitForceGrey;
      case HitForce.okay:
        return s.hitForceOrange;
      case HitForce.splendid:
        return s.hitForceRed;
      default:
        return '';
    }
  })();

  return (
    <div className={cn(s.root, className)}>
      <div className={s.container}>
        <div
          className={cn(s.hpChange, s.right, hitForceColor, {
            [s.animate]: isPlayerTurn,
          })}
          style={{ animationDelay: `${versusService.hpAnimationDelay}ms` }}
        >
          {playerDamage > 0 ? `-${playerDamage}` : playerDamage}
        </div>
        <div
          className={cn(s.hpChange, s.left, hitForceColor, {
            [s.animate]: !isPlayerTurn,
          })}
        >
          {opponentDamage > 0 ? `-${opponentDamage}` : opponentDamage}
        </div>
      </div>
    </div>
  );
};
