import cn from 'classnames';

import s from './EyeBlinkAnimation.module.scss';

interface EyeBlinkAnimationProps {
  className?: string;
  onAnimationEnd: () => void;
}

export const EyeBlinkAnimation: React.FC<EyeBlinkAnimationProps> = ({
  className,
  onAnimationEnd,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <div className={s.eye} onAnimationEnd={onAnimationEnd}></div>
    </div>
  );
};
