import cn from 'classnames';
import { useEffect, useState } from 'react';

import { onboardingService, versusService } from '@app/services';
import { useServiceState } from '@app/common/state';
import s from './VersusConnectionLost.module.scss';
import { AnimatePresence, m } from 'framer-motion';

interface VersusConnectionLostProps {
  className?: string;
}

export const VersusConnectionLost: React.FC<VersusConnectionLostProps> = ({
  className,
}) => {
  const [show, setShow] = useState(false);
  const { isOnboarding } = useServiceState(onboardingService, ['isOnboarding']);
  const { isConnected } = useServiceState(versusService, ['isConnected']);

  useEffect(() => {
    if (!isOnboarding && !isConnected) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isConnected, isOnboarding]);

  if (show === false) {
    return null;
  }

  return (
    <AnimatePresence>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={s.root}
      >
        <div className={s.content}>
          <div>Internet connections is lost!</div>
          <div>Trying to reconnect...</div>
        </div>
      </m.div>
    </AnimatePresence>
  );
};
